import React, { useEffect, useState } from 'react';
import { host, mainColor, v } from '../../../config';
import { Mail, MapPin, Phone } from 'lucide-react';

const ShowTutorProfile = () => {

    const params = new URLSearchParams(window.location.search);

    const [profile, Profile] = useState({});
    const [tutorSkills, setTutorSkills] = useState([]);
    const [tutorExperiences, setTutorExperiences] = useState([]);
    const [tutorAwards, setTutorAwards] = useState([]);
    const [tutorAchivements, setTutorAchivements] = useState([]);

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchTutorData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${host}/api/${v}/visitor/tutor_profile?${new URLSearchParams({
                tutorString: window.location.pathname.split('/')[2]
            })}`, {

            });
            if (response.ok) {
                const data = await response.json();
                Profile(data[0]);
            } else {
                const error = await response.text();
                setMessage(error || 'Verification failed');
            }
        } catch (err) {
            setMessage('Error occurred during verification');
        }
        setLoading(false);
    };

    const fetchTutorSkills = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${host}/api/${v}/visitor/tutor_skills?${new URLSearchParams({
                tutorString: window.location.pathname.split('/')[2]
            })}`);
            if (response.ok) {
                const data = await response.json();
                let skillNames = data.skills.map((skill) => skill.name);
                setTutorSkills(skillNames)
            } else {
                const error = await response.text();
                setMessage(error || 'Verification failed');
            }
        } catch (err) {
            setMessage('Error occurred during verification');
        }
        setLoading(false);
    };

    async function fetchTutorExperiences() {
        try {
            const response = await fetch(`${host}/api/${v}/visitor/tutor_experience?${new URLSearchParams({
                tutorString: window.location.pathname.split('/')[2]
            })}`);

            if (response.ok) {
                const data = await response.json();
                setTutorExperiences(data.experiences)
            } else {
                const error = await response.text();
                setMessage(error || 'Verification failed');
            }

        } catch { }
    }


    async function getAchivement() {
        try {
            const response = await fetch(`${host}/api/${v}/visitor/tutor_achievement?${new URLSearchParams({
                tutorString: window.location.pathname.split('/')[2]
            })}`);

            if (response.ok) {
                const data = await response.json();
                setTutorAchivements(data.achivements);
            } else {
                const error = await response.text();
                setMessage(error || 'Verification failed');
            }
        }
        catch { }
    }


    async function getAward() {
        try {
            const response = await fetch(`${host}/api/${v}/visitor/tutor_awards?${new URLSearchParams({
                tutorString: window.location.pathname.split('/')[2]
            })}`);

            if (response.ok) {
                const data = await response.json();
                setTutorAwards(data.awards);
            } else {
                const error = await response.text();
                setMessage(error || 'Verification failed');
            }
        }
        catch { }
    }



    useEffect(() => { fetchTutorData(); fetchTutorSkills(); fetchTutorExperiences(); getAchivement(); getAward(); }, [])

    return (
        <div className='min-h-screen mx-20 flex gap-4 flex-wrap justify-center items-center'>

            {params.get('v') &&
                <div>
                    <video
                        controls
                        width="400px"
                        height="auto"
                        className="rounded-lg shadow-lg"
                    >
                        <source src={profile.videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className='font-bold text-xl text-center mt-4'>  Demo Session by {profile.name}</div>
                </div>
            }

            <div id="resume" className={`max-w-4xl mx-auto border-2 max-w-4xl mx-auto border-2 border-[${mainColor}] bg-white rounded p-8 bg-white rounded p-8`}>

                {/* Header Section */}
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center">
                        <div className={`w-32 h-32 rounded-full border-2 border-[${mainColor}] overflow-hidden bg-teal-600 mr-8`} >
                            <img
                                src={`https://tutorplatter_back.softvisionary.com/tutor_profile/${profile.tutorId}.png`}
                                alt="Profile"
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <div>
                            <h1 className="text-4xl font-bold">{profile.name}</h1>
                            <h2 className="text-2xl text-teal-600 mt-2">Tutor</h2>
                        </div>
                    </div>

                    <img
                        alt="Tutor Platter"
                        className="h-20 mb-4"
                        src="https://usercentereddesign.co.in/tutor/images/logo.png"
                    />
                </div>




                {/* Main Content */}
                <div className="grid grid-cols-3 gap-8">
                    {/* Left Column */}
                    <div className="col-span-1">
                        {/* Contact */}
                        <section className="mb-8">
                            <h3 className="text-lg font-bold border-b-2 border-teal-600 pb-2 mb-4">
                                CONTACT
                            </h3>
                            <div className="space-y-3">
                                <div className="flex items-center">
                                    <Mail size={16} className="mr-2" />
                                    <span>{profile.email}</span>
                                </div>
                                <div className="flex items-center">
                                    <Phone size={16} className="mr-2" />
                                    <span>{profile.phone1}</span>
                                </div>
                                <div className="flex items-center">
                                    <MapPin size={16} className="mr-2" />
                                    <span>
                                        <strong>Pincode</strong> : {profile.pincode}
                                    </span>
                                </div>
                            </div>
                        </section>

                        {/* Profile Summary */}
                        <section className="mb-8">
                            <h3 className="text-lg font-bold border-b-2 border-teal-600 pb-2 mb-4">
                                PROFILE SUMMARY
                            </h3>
                            <p className="text-sm">
                                Business development manager looking to obtain a challenging
                                position in a organization, utilizing my proven track record in
                                driving revenue growth and forging strategic partnerships to
                                achieve business objectives.
                            </p>
                        </section>

                        {/* Skills */}
                        <section className="mb-8">
                            <h3 className="text-lg font-bold border-b-2 border-teal-600 pb-2 mb-4">
                                SKILLS
                            </h3>
                            <div className="flex flex-wrap gap-2 max-w-full">
                                {tutorSkills.map((skill, index) => (
                                    <div
                                        key={index}
                                        className={`bg-green-100 text-gray-800 rounded-lg px-4 py-1 text-sm 
                           cursor-pointer border border-[${mainColor}]`}
                                    >
                                        {skill}
                                    </div>
                                ))}
                            </div>
                        </section>

                    </div>

                    {/* Right Column */}
                    <div className="col-span-2">
                        {/* Professional Experience */}
                        <section className="mb-8">
                            <h3 className="text-lg font-bold border-b-2 border-teal-600 pb-2 mb-4">
                                PROFESSIONAL EXPERIENCE
                            </h3>

                            {tutorExperiences.map((experience, index) => {
                                return (
                                    <div className="mb-6">
                                        <h4 className="font-bold">{experience.name}</h4>
                                        <p className="text-gray-600">Tutor</p>
                                        <span className="mt-2">{experience.description}</span>
                                    </div>
                                );
                            })}
                        </section>

                        {/* Education */}
                        <section>
                            <h3 className="text-lg font-bold border-b-2 border-teal-600 pb-2 mb-4">
                                Awards
                            </h3>

                            {tutorAwards.map((award, index) => {
                                return (
                                    <React.Fragment>
                                        <div className="mb-4">
                                            <h4 className="font-bold">{award.name}</h4>
                                            <p className="text-gray-600">{award.date}</p>
                                            <p className="text-sm text-gray-500">
                                                {award.description}
                                            </p>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </section>

                        {/* Achivement */}
                        <section>
                            <h3 className="text-lg font-bold border-b-2 border-teal-600 pb-2 mb-4">
                                Achivements
                            </h3>

                            {tutorAchivements.map((achivement, index) => {
                                return (
                                    <React.Fragment>
                                        <div className="mb-4">
                                            <h4 className="font-bold">{achivement.name}</h4>
                                            <p className="text-gray-600">{achivement.date}</p>
                                            <p className="text-sm text-gray-500">
                                                {achivement.description}
                                            </p>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowTutorProfile;