import React, { useState } from "react";
import styles from "./styles.module.css";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faIndianRupeeSign,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { host, v } from "../../../config";
import SizedBox from "../../CommonWidget/SizedBox/SizedBox";

const FeaturedJob = ({
  details,
  showAply = true,
  appiled,
  appliedDate = "",
}) => {
  const [loading, Loading] = useState(false);
  const [show, Show] = useState(false);

  // const dateString =details.postingDate;
  const dateString = details.postingDate || "2024-02-28 18:31:20";
  const date = new Date(dateString);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const monthIndex = date.getUTCMonth();
  const year = date.getUTCFullYear().toString().slice(-2);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[monthIndex].slice(0, 3);

  const applyDate = new Date(appliedDate);

  function applyJob() {
    Loading(true);
    fetch(`${host}/api/${v}/tutor/applyjob`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify({ jobId: details.jobId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        alert("Applied to Job Successfully");
        if (data.message === "success") {
          window.location.reload();
        }
        // setJobs(data.jobs);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }
  return (
    <div className={styles.horizontalCard}>
      <hr />
      <img
        src={`${host}/institution_profile/${details.institutionId}.png`}
        alt="job Img"
        className={styles.cardImage}
      />
      <div className={styles.cardContent}>
        <div className={styles.insName}>{details.institutionName}</div>
        <div className={styles.cardTitle}>{details.jobName}</div>

        <div className={`${styles.flex} ${styles.rowMembers}`}>
          <div className={styles.member}>
            <FontAwesomeIcon icon={faIndianRupeeSign} /> {details.salary_range}
          </div>

          <div className={styles.member}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            {" " +
              details.city +
              ", " +
              details.country +
              "-" +
              details.pincode}
          </div>

          <div className={styles.member}>
            <FontAwesomeIcon icon={faClock} />
            {` ${day} ${monthName} ${year}`}
          </div>
        </div>

        <SizedBox height="10px" />

        {show ? (
          <div style={styles.description}>
            {details.jobDescription}
            <span className={styles.btn} onClick={() => Show(false)}>
              Less
            </span>
          </div>
        ) : (
          <div style={styles.description}>
            {details.jobDescription.substring(0, 200)}
            {details.jobDescription.length > 200 && (
              <span className={styles.btn} onClick={() => Show(true)}>
                ...More
              </span>
            )}
          </div>
        )}
      </div>

      {showAply ? (
        <div>
          {loading ? (
            <LoadingSpinner />
          ) : appiled ? (
            `Already Applied on ${applyDate.toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })}`
          ) : (
            <div className={styles.cardButton} onClick={() => applyJob()}>
              Quick Apply
            </div>
          )}
        </div>
      ) : (
        <SizedBox />
      )}
    </div>
  );
};

export default FeaturedJob;
