// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBU3ZQtvacoUUbN_9nHsoK0ycYmloQB5Rc",
  authDomain: "astro1-astro.firebaseapp.com",
  projectId: "astro1-astro",
  storageBucket: "astro1-astro.firebasestorage.app",
  messagingSenderId: "360716369845",
  appId: "1:360716369845:web:bc586d9e59b09bba8363fc"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;