import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Calendar, Edit2, MapPin, X } from "lucide-react";
import InstitutionNavbar from "../InstitutionNavbar/InstitutionNavbar";
import styles from "./styles.module.css";
import Sidebar from "../Sidebar/Sidebar";
import SizedBox from "../../Widgets/SizedBox";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from "../../../config";
import { Link } from "react-router-dom";

const InstitutionPostJobs = () => {
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingJobId, setEditingJobId] = useState(null);
  const [editedDescription, setEditedDescription] = useState("");
  const [loadingCloseJob, setLoadingCloseJob] = useState(false);
  const [reason, setReason] = useState("");
  const [selectedJobId, setSelectedJobId] = useState(null);

  useEffect(() => getJobs(), []);

  function getJobs() {
    setLoading(true);
    fetch(`${host}/api/${v}/institution/job`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        setJobs(
          data.jobs.map((result) => ({
            ...result,
            isSelected: false,
          }))
        );
      })
      .catch((error) => console.error("Error during fetch:", error))
      .finally(() => setLoading(false));
  }

  const handleBoxChange = (id, value) => {
    setJobs(
      jobs.map((job) =>
        job.jobId === id ? { ...job, isSelected: value } : job
      )
    );
  };

  const handleCloseJob = () => {
    if (!reason) {
      alert("Please Select a Reason before Closing");
      return;
    }

    setLoadingCloseJob(true);
    fetch(`${host}/api/v1/institution/close_a_job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify({ jobId: selectedJobId, reason }),
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        if (response.status === 200) {
          setShowModal(false);
          alert("Job Closing Successful!");
          getJobs();
        }
        return response.json();
      })
      .catch((error) => console.error("Error during fetch:", error))
      .finally(() => setLoadingCloseJob(false));
  };

  const handleCloseSelectedJobs = () => {
    const selectedJobs = jobs.filter((job) => job.isSelected);
    const jobIdArray = selectedJobs.map((job) => job.jobId);

    fetch(`${host}/api/${v}/institution/close_multiple_job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify({ jobIds: jobIdArray, reason: "Multiple Close" }),
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        if (response.status === 200) {
          alert("Jobs Closed Successfully!");
          getJobs();
        }
        return response.json();
      })
      .catch((error) => console.error("Error during fetch:", error));
  };

  const handleCloseAllJobs = () => {
    if (!window.confirm("Are you Sure to Close All Jobs?")) return;

    fetch(`${host}/api/${v}/institution/close_all_job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        if (response.status === 200) {
          alert("All Jobs Closed Successfully!");
          getJobs();
        }
        return response.json();
      })
      .catch((error) => console.error("Error during fetch:", error));
  };

  const handleUpdateDescription = (jobId) => {
    fetch(`${host}/api/${v}/institution/job`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify({
        jobId,
        description: editedDescription,
      }),
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        if (response.status === 200) {
          alert("Description updated successfully!");
          setEditingJobId(null);
          getJobs();
        }
        return response.json();
      })
      .catch((error) => console.error("Error updating description:", error));
  };

  const renderJob = (value, i) => {
    const parsedDatetime = new Date(value.createdAt);
    const formattedDate = `${parsedDatetime.getDate()}${parsedDatetime.toLocaleString(
      "default",
      { month: "short" }
    )}${parsedDatetime.getFullYear()}`;

    return (
      <div
        key={i}
        className="flex items-center gap-4 p-4 border rounded-lg shadow-sm hover:shadow-md transition-shadow"
      >
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={value.isSelected}
            onChange={(e) => handleBoxChange(value.jobId, e.target.checked)}
            className="mr-2 h-4 w-4"
          />
          <span>{i + 1}</span>
        </div>

        <img
          src="https://www.teachaway.com/sites/default/files/styles/header_image_1024x575/public/job-board-main_0.png?itok=P_hWWo-G"
          alt=""
          className="w-24 h-24 object-cover rounded-md cursor-pointer"
        />

        <div className="flex-1">
          <h3 className="text-lg font-semibold mb-2">{value.name}</h3>
          <div className="flex gap-8 items-center mb-2">
            <div className="flex items-center gap-1 text-gray-600">
              <MapPin size={16} />
              <span>Pincode: {value.pincode}</span>
            </div>
            <div className="flex items-center gap-1 text-gray-600">
              <Calendar size={16} />
              Posted on: {formattedDate}
            </div>
          </div>

          {editingJobId === value.jobId ? (
            <div className="flex gap-2">
              <textarea
                value={editedDescription}
                onChange={(e) => setEditedDescription(e.target.value)}
                className="w-full p-2 border rounded-md"
                rows={3}
              />
              <div className="flex flex-col gap-2">
                <button
                  onClick={() => handleUpdateDescription(value.jobId)}
                  className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setEditingJobId(null);
                    setEditedDescription("");
                  }}
                  className="px-3 py-1 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className="flex items-start gap-2">
              <p className="w-full text-gray-700 border bg-gray-100 rounded-md px-2 py-4">
                {value.description}
              </p>
              <button
                onClick={() => {
                  setEditingJobId(value.jobId);
                  setEditedDescription(value.description);
                }}
                className="flex items-center p-2 border border-blue-100 rounded-lg text-blue-500 hover:text-blue-600 hover:bg-blue-600 hover:text-white"
              >
                <Edit2 size={16} /> (edit)
              </button>
            </div>
          )}
        </div>

        <div className="flex flex-col items-end gap-2">
          <Link
            to={`${value.jobId}`}
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
            disabled={loadingCloseJob}
          >
            View Applicants
          </Link>
          <button
            onClick={() => {
              setSelectedJobId(value.jobId);
              setShowModal(true);
            }}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
            disabled={loadingCloseJob}
          >
            Close Job
          </button>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <InstitutionNavbar title="Jobs Posted by you" />
      <div className="flex">
        <div>
          <Sidebar />
        </div>
        <div className={styles.card}>
          <div className={styles.topbox}>
            <div className="flex justify-between">
              <div className="text-2xl">Jobs Posted by You</div>
              <div>
                Found: {jobs.length} Job{jobs.length !== 1 ? "s" : ""}
              </div>
            </div>
            <SizedBox height="26px" />
            {loading ? (
              <div className={styles.noJobs}>
                <LoadingSpinner />
              </div>
            ) : jobs.length ? (
              <React.Fragment>
                <div className="flex flex-gap-4 mb-4">
                  <Button variant="contained" onClick={handleCloseSelectedJobs}>
                    Close Selected Jobs
                  </Button>
                  <SizedBox width="10px" />
                  <Button variant="contained" onClick={handleCloseAllJobs}>
                    Close All Jobs
                  </Button>
                </div>
                <div className="flex flex-col gap-2">{jobs.map(renderJob)}</div>
              </React.Fragment>
            ) : (
              <div className={styles.noJobs}>No Jobs Posted yet!</div>
            )}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-96">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Reason to Close Job</h2>
              <button
                onClick={() => setShowModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={20} />
              </button>
            </div>

            <select
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              className="w-full p-2 border rounded-md mb-4"
            >
              <option value="">Select a reason</option>
              <option value="Already Hired">Already Hired</option>
              <option value="Hire Through Another Source">
                Hire through another Source
              </option>
              <option value="No More Required">No More Required</option>
              <option value="Duplicate Job">Duplicate Job</option>
            </select>

            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 border rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleCloseJob}
                disabled={loadingCloseJob}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 disabled:bg-red-300"
              >
                {loadingCloseJob ? "Closing..." : "Close Job"}
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default InstitutionPostJobs;
