import React, { useEffect, useState } from "react";
import { host, v } from "../../../config";
import { ChevronLeft, ChevronRight, Search, Users, CheckCircle } from "lucide-react";
import { Link }  from "react-router-dom";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import Footer from "../../CommonWidget/Footer/Footer";

const Students = () => {
  const [loading, setLoading] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const steps = [
    {
      icon: <Search className="w-8 h-8 text-blue-500" />,
      title: "Search for Tutors",
      description: "Explore a wide range of experienced tutors for all subjects and levels."
    },
    {
      icon: <Users className="w-8 h-8 text-blue-500" />,
      title: "Connect",
      description: "Contact your chosen tutor directly through the portal."
    },
    {
      icon: <CheckCircle className="w-8 h-8 text-blue-500" />,
      title: "Start Learning",
      description: "Begin your personalized tuition sessions and achieve your academic goals."
    }
  ];

  const getJobs = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${host}/api/${v}/visitor/jobs`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setFilteredJobs(data.jobs);
    } catch (error) {
      console.error("Error during fetch:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  const FeatureCard = ({ icon, title, description, color }) => (
    <div className="text-center px-4 py-6">
      <div
        className={`w-20 h-20 ${color} rounded-full mx-auto mb-4 flex items-center justify-center`}
      >
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );

  const TutorCard = ({ image, name, expertise }) => (
    <div className="bg-white rounded-lg shadow-lg p-4 mx-2">
      <img
        src={image}
        alt={name}
        className="w-48 h-48 object-cover rounded-lg mx-auto mb-4"
      />
      <h3 className="text-lg font-semibold text-center">{name}</h3>
      <p className="text-gray-500 text-center text-sm">{expertise}</p>
    </div>
  );

  const StepCard = ({ icon, title, description }) => (
    <div className="flex flex-col items-center text-center">
      <div className="w-16 h-16 bg-white rounded-full shadow-md flex items-center justify-center mb-4">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-500 max-w-xs">{description}</p>
    </div>
  );

  const features = [
    {
      icon: (
        <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
          <svg
            viewBox="0 0 24 24"
            className="w-6 h-6 text-red-500"
            fill="none"
            stroke="currentColor"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" />
            <path d="M12 8v8m-4-4h8" />
          </svg>
        </div>
      ),
      title: "Personalized Attention",
      description: "Learn at your own pace with one-on-one sessions.",
      color: "bg-red-500",
    },
    {
      icon: (
        <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
          <svg
            viewBox="0 0 24 24"
            className="w-6 h-6 text-yellow-500"
            fill="none"
            stroke="currentColor"
          >
            <circle cx="12" cy="8" r="5" />
            <path d="M3 21h18M12 11v10" />
          </svg>
        </div>
      ),
      title: "Expert Guidance",
      description: "Get support from qualified tutors with subject expertise.",
      color: "bg-yellow-500",
    },
    {
      icon: (
        <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
          <svg
            viewBox="0 0 24 24"
            className="w-6 h-6 text-blue-500"
            fill="none"
            stroke="currentColor"
          >
            <circle cx="12" cy="12" r="4" />
            <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-4 8" />
          </svg>
        </div>
      ),
      title: "Convenience",
      description:
        "Choose flexible schedules and study from the comfort of your home or online.",
      color: "bg-blue-500",
    },
  ];

  const tutors = [
    {
      image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1522&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "Jacob Jones",
      expertise: "UI/UX Design Expert",
    },
    {
      image: "https://images.unsplash.com/photo-1542596768-5d1d21f1cf98?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDZ8fHxlbnwwfHx8fHw%3D",
      name: "Sarah Smith",
      expertise: "Social Media Expert",
    },
    {
      image: "https://images.unsplash.com/photo-1504257432389-52343af06ae3?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "David Brown",
      expertise: "Business Plan Expert",
    },
    {
      image: "https://images.unsplash.com/photo-1528892952291-009c663ce843?q=80&w=2144&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "Michael Lee",
      expertise: "Photography Expert",
    },
    {
      image: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "Robert Fox",
      expertise: "Marketing Expert",
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % (tutors.length - 2));
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + (tutors.length - 2)) % (tutors.length - 2)
    );
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Navbar />

      <section className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto text-center">
          {/* Main heading with two lines */}
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            Unlock Your Potential with
            <br />
            <span className="text-emerald-500">Expert Tutors!</span>
          </h1>

          {/* Subheading */}
          <p className="text-gray-600 text-lg md:text-xl mb-8 max-w-3xl mx-auto">
            Struggling with your studies or aiming for academic excellence? Find
            the perfect tutor for private tuition tailored to your needs.
          </p>

          {/* CTA Button */}
          <Link to='/salesteam' className="bg-blue-600 text-white px-8 py-4 rounded-lg text-lg font-medium hover:bg-blue-700 transition-colors">
            Explore Tutors Now
          </Link>
          
        </div>
      </section>

      <div className="container mx-auto px-4 py-8">
        <div className="relative max-w-5xl mx-auto">
          {/* Main image wrapper with curved border and gradient overlay */}
          <div className="rounded-3xl overflow-hidden shadow-xl">
            {/* Dark gradient overlay at bottom */}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-navy-900/10 z-10"></div>

            {/* Image */}
            <img
              src="/images/featured.png"
              alt="Students collaborating"
              className="w-full h-full object-cover"
            />
          </div>

          {/* Decorative curved border at bottom */}
          <div className="absolute -bottom-1 left-0 right-0 h-8 bg-white rounded-t-[50px]"></div>
        </div>
      </div>
      <section className="container mx-auto px-4 py-16">
        {/* Features Section */}
        <div className="grid md:grid-cols-3 gap-8 mb-24">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>

        {/* Best Tutors Section */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-2">
            Our Best <span className="text-emerald-500">Tutor</span>
          </h2>
          <p className="text-gray-600">
            Find Your Perfect Tutor for Personalized Learning!
          </p>
        </div>

        {/* Tutor Carousel */}
        <div className="relative max-w-6xl mx-auto">
          <div className="flex overflow-hidden">
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${currentSlide * 25}%)` }}
            >
              {tutors.map((tutor, index) => (
                <TutorCard key={index} {...tutor} />
              ))}
            </div>
          </div>

          {/* Navigation Buttons */}
          <button
            onClick={prevSlide}
            className="absolute left-0 top-1/2 -translate-y-1/2 bg-white p-2 rounded-full shadow-lg hover:bg-gray-100"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 -translate-y-1/2 bg-white p-2 rounded-full shadow-lg hover:bg-gray-100"
          >
            <ChevronRight className="w-6 h-6" />
          </button>

          {/* Dots */}
          <div className="flex justify-center gap-2 mt-6">
            {[...Array(tutors.length - 2)].map((_, index) => (
              <button
                key={index}
                className={`w-2 h-2 rounded-full ${
                  currentSlide === index ? "bg-emerald-500" : "bg-gray-300"
                }`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
        </div>
      </section>

     

      <section className="bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        {/* Heading */}
        <h2 className="text-4xl font-bold text-center mb-16">
          How It <span className="text-[#7BC144]">Works?</span>
        </h2>

        {/* Steps */}
        <div className="relative max-w-5xl mx-auto">
          {/* Connecting Lines */}
          <div className="hidden md:block absolute top-8 left-1/4 right-1/4 h-0.5">
            <div className="relative w-full h-full">
              {/* First arrow */}
              <div className="absolute w-1/2 h-full border-t-2 border-dashed border-emerald-300">
                <div className="absolute right-0 -top-1.5 w-3 h-3 border-t-2 border-r-2 border-emerald-300 transform rotate-45"></div>
              </div>
              {/* Second arrow */}
              <div className="absolute left-1/2 w-1/2 h-full border-t-2 border-dashed border-emerald-300">
                <div className="absolute right-0 -top-1.5 w-3 h-3 border-t-2 border-r-2 border-emerald-300 transform rotate-45"></div>
              </div>
            </div>
          </div>

          {/* Step Cards */}
          <div className="grid md:grid-cols-3 gap-12">
            {steps.map((step, index) => (
              <StepCard key={index} {...step} />
            ))}
          </div>
        </div>
      </div>
    </section>

      <section className="container mx-auto px-4 py-16">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center gap-12">
          {/* Left side - Image with decorative elements */}
          {/* Main image */}
          <div className="md:w-1/2 overflow-hidden">
            <img
              src="/images/hireTeacher2.png"
              alt="Students studying together"
              className="w-full h-full object-cover"
            />
          </div>

          {/* Right side - Content */}
          <div className="md:w-1/2 flex flex-col gap-6">
            <h2 className="text-4xl font-bold">
              Find Your Ideal{" "}
              <span className="text-emerald-500">Tutor Today!</span>
            </h2>

            <p className="text-gray-600 text-lg">
              Whether you're preparing for exams or mastering new skills, we
              have the right tutor for you.
            </p>

            <Link to='/salesteam'  className="w-fit bg-emerald-500 text-white px-6 py-3 rounded-md hover:bg-emerald-600 transition-colors">
              Explore Tutors Now
            </Link>
          </div>
        </div>
      </section>

       {/* <main className="flex-1 container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
          <h2 className="text-2xl font-bold text-gray-800">Featured Jobs</h2>
          <p className="text-gray-600 mt-2 md:mt-0">
            Found {filteredJobs.length} Jobs
          </p>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-gray-200 border-t-blue-600"></div>
          </div>
        ) : filteredJobs.length === 0 ? (
          <div className="flex justify-center items-center h-64">
            <p className="text-gray-500 text-lg">No Job Found!</p>
          </div>
        ) : (
          <div className="space-y-4">
            {filteredJobs.map((job, index) => (
              <FeaturedJob value={job} />
            ))}
          </div>
        )}
      </main> */}

   <Footer />
    </div>
  );
};

export default Students;
