import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Eye, EyeOff, X } from "lucide-react";
import { host, v } from "../../../config";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";

const RegistrationForm = () => {
  const navigate = useNavigate();
  const [userType, setUserType] = useState("tutor");

  const [loading, Loading] = useState(false);
  const [name, Name] = useState("");
  const [contactPerson, ContactPerson] = useState("");
  const [email, Email] = useState("");
  const [password, Password] = useState("");
  const [confPassword, ConfPassword] = useState("");
  const [phone, Phone] = useState("");
  const [pincode, Pincode] = useState("");
  const [add, Add] = useState("");
  const [pref, Pref] = useState("");
  const [scTyp1, ScTyp1] = useState("Private");
  const [scTyp2, ScTyp2] = useState("Primary School");
  const [annualFee, AnnualFee] = useState("demo");

  const [showPass, ShowPass] = useState(false);
  const [showConfPass, ShowConfPass] = useState(false);

  function handleTutorSignup() {
    if (
      !(
        name.trim().length &&
        email.trim().length &&
        password.trim().length &&
        confPassword.trim().length &&
        pincode.trim().length
      )
    ) {
      alert("Please fill All Details");
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Please Enter a Valid Email address");
      return;
    }

    if (password.trim().length < 6) {
      alert("Passwords must be at least 6 characters");
      return;
    }
    if (!(/[a-z]/.test(password.trim()) && /[A-Z]/.test(password.trim()))) {
      alert("Password must have a Small Case & Upper Case Alphabet");
      return;
    }

    if (password != confPassword) {
      alert("Passwords do not Match");
      return;
    }

    Loading(true);

    fetch(`${host}/api/${v}/visitor/tutorregister`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: name,
        email: email,
        pincode: pincode,
        teaching_pref: pref,
        password: password,
      }),
    })
      .then((response) => {
        if (response.status === 400) {
          // handleClick({ vertical: "top", horizontal: "right" });
        }
        if (!response.ok) {
          if (response.status === 403) {
            alert("Email Already Exist!");
          }
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === true) {
          alert("Registration success");
          navigate("/tutorlogin");
        }
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }

  function handleInstitutionSignup() {
    if (
      !(
        name.trim().length &&
        contactPerson.trim().length &&
        email.trim().length &&
        password.trim().length &&
        confPassword.trim().length &&
        phone.trim().length &&
        pincode.trim().length &&
        add.trim().length
      )
    ) {
      alert("Please Fill All details");
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Please Enter a Valid Email address");
      return;
    }

    if (!/^\d+$/.test(phone)) {
      alert("Not a Phone Number");
      return;
    }
    if (phone.length != 10) {
      alert("Phone Number must be 10 digits Long");
      return;
    }

    if (password.trim().length < 6) {
      alert("Passwords must be at least 6 characters");
      return;
    }
    if (!(/[a-z]/.test(password.trim()) && /[A-Z]/.test(password.trim()))) {
      alert("Password must have a Small Case & Upper Case Alphabet");
      return;
    }

    if (password != confPassword) {
      alert("Passwords do not Match");
      return;
    }

    Loading(true);

    fetch(`${host}/api/${v}/visitor/institutionregister`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: name,
        contactPerson: contactPerson,
        email: email,
        pass: password,
        phone: phone,
        scTyp0: "Individual",
        scTyp1: scTyp1,
        scTyp2: scTyp2,
        annualFee: annualFee,
        pincode: pincode,
        add: add,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 403) {
            alert("Email Already Exist!");
          }
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.msg === "success") {
          alert("Registration Success");
          navigate("/tutorlogin");
        }
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        Loading(false);
      });
  }


  
  function handleStudentSignup() {
    if (
      !(
        name.trim().length &&
        email.trim().length &&
        password.trim().length &&
        confPassword.trim().length &&
        pincode.trim().length &&
        add.trim().length
      )
    ) {
      alert("Please Fill All details");
      return;
    }

    
    if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email)){
      alert("Please Enter a Valid Email address");
      return;
    }

    if (password.trim().length < 6) {
      alert("Passwords must be at least 6 characters");
      return;
    }
    if (!(/[a-z]/.test(password.trim()) && /[A-Z]/.test(password.trim()))) {
      alert("Password must have a Small Case & Upper Case Alphabet");
      return;
    }

    if (password != confPassword) {
      alert("Passwords do not Match");
      return;
    }

    Loading(true);

    fetch(`${host}/api/${v}/visitor/institutionregister`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: name,
        contactPerson: name,
        email: email,
        pass: password,
        phone: phone || '0',
        scTyp0: 'Student',
        scTyp1: "Private",
        scTyp2: "Primary School",
        annualFee: 1000,
        pincode: pincode,
        add: add,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if(response.status === 403){
            alert('Email Already Exist!');
          }
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.msg === "success") {
          alert("Registration Success");
          navigate("/tutorlogin");
        }
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        Loading(false);
      });
  }


  return (
    <>
      <Navbar />
      <div className="min-h-[88vh] flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 p-6 lg:p-12 flex flex-col items-center justify-between">
          <div className="flex gap-2 items-center">
            <label className="flex items-center p-3 cursor-pointer hover:bg-gray-50 transition-colors">
              <input
                type="radio"
                name="userType"
                value="tutor"
                checked={userType === "tutor"}
                onChange={() => setUserType("tutor")}
                className="w-4 h-4 text-blue-500"
              />
              <span className="ml-2">Tutor</span>
            </label>

            <label className="flex items-center p-3 cursor-pointer hover:bg-gray-50 transition-colors">
              <input
                type="radio"
                name="userType"
                value="institution"
                checked={userType === "institution"}
                onChange={() => setUserType("institution")}
                className="w-4 h-4 text-blue-500"
              />
              <span className="ml-2">Institution</span>
            </label>

            <label className="flex items-center p-3 cursor-pointer hover:bg-gray-50 transition-colors">
              <input
                type="radio"
                name="userType"
                value="student"
                checked={userType === "student"}
                onChange={() => setUserType("student")}
                className="w-4 h-4 text-blue-500"
              />
              <span className="ml-2">Student</span>
            </label>
          </div>

          <div className="w-full max-w-md space-y-6">
            {userType === "tutor" && (
              <form className="w-full max-w-md mx-auto space-y-4 p-6">
                <div className="space-y-4">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => Name(e.target.value)}
                    placeholder="Name"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                    autoFocus
                  />

                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => Email(e.target.value)}
                    placeholder="Email Address"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                  />

                  <input
                    type="text"
                    id="pincode"
                    name="pincode"
                    value={pincode}
                    onChange={(e) => Pincode(e.target.value)}
                    placeholder="Pincode"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                  />

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Teaching Preference
                    </label>
                    <select
                      value={pref}
                      onChange={(e) => Pref(e.target.value)}
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    >
                      <option value="Private">Online</option>
                      <option value="Government">Offline</option>
                      <option value="Both">Both</option>
                    </select>
                  </div>

                  <div className="relative">
                    <input
                      type={showPass ? "text" : "password"}
                      id="password"
                      name="password"
                      value={password}
                      onChange={(e) => Password(e.target.value)}
                      placeholder="Password"
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => ShowPass(!showPass)}
                      className="absolute right-3 top-2.5 text-gray-500 hover:text-gray-700"
                    >
                      {showPass ? <EyeOff size={20} /> : <Eye size={20} />}
                    </button>
                  </div>

                  <div className="relative">
                    <input
                      type={showConfPass ? "text" : "password"}
                      id="confirm-password"
                      name="confirm-password"
                      value={confPassword}
                      onChange={(e) => ConfPassword(e.target.value)}
                      placeholder="Confirm Password"
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => ShowConfPass(!showConfPass)}
                      className="absolute right-3 top-2.5 text-gray-500 hover:text-gray-700"
                    >
                      {showConfPass ? <EyeOff size={20} /> : <Eye size={20} />}
                    </button>
                  </div>
                </div>

                <button
                  type="button"
                  onClick={handleTutorSignup}
                  disabled={loading}
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? <LoadingSpinner /> : "Tutor Sign Up"}
                </button>
              </form>
            )}

            {userType === "institution" && (
              <form className="space-y-4">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => Name(e.target.value)}
                  placeholder="Institution/Employer Name"
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  required
                  autoFocus
                />

                <input
                  type="text"
                  value={contactPerson}
                  onChange={(e) => ContactPerson(e.target.value)}
                  placeholder="Registering Person Name"
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  required
                />

                <input
                  type="email"
                  value={email}
                  onChange={(e) => Email(e.target.value)}
                  placeholder="Email"
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  required
                />

                <div className="relative">
                  <input
                    type={showPass ? "text" : "password"}
                    value={password}
                    onChange={(e) => Password(e.target.value)}
                    placeholder="Password"
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => ShowPass(!showPass)}
                    className="absolute right-3 top-2.5 text-gray-500 hover:text-gray-700"
                  >
                    {showPass ? <EyeOff size={20} /> : <Eye size={20} />}
                  </button>
                </div>

                <div className="relative">
                  <input
                    type={showConfPass ? "text" : "password"}
                    value={confPassword}
                    onChange={(e) => ConfPassword(e.target.value)}
                    placeholder="Confirm Password"
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => ShowConfPass(!showConfPass)}
                    className="absolute right-3 top-2.5 text-gray-500 hover:text-gray-700"
                  >
                    {showConfPass ? <EyeOff size={20} /> : <Eye size={20} />}
                  </button>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium">
                    Organization Type(i)
                  </label>
                  <select
                    value={scTyp1}
                    onChange={(e) => ScTyp1(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="Private">Private</option>
                    <option value="Government">Government</option>
                  </select>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium">
                    Organization Type(ii)
                  </label>
                  <select
                    value={scTyp2}
                    onChange={(e) => ScTyp2(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="Primary School">Primary School</option>
                    <option value="High School">High School</option>
                    <option value="College">College</option>
                  </select>
                </div>

                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => Phone(e.target.value)}
                  placeholder="Phone Number"
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  required
                />

                <input
                  type="text"
                  value={pincode}
                  onChange={(e) => Pincode(e.target.value)}
                  placeholder="Pincode"
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  required
                />

                <textarea
                  value={add}
                  onChange={(e) => Add(e.target.value)}
                  placeholder="Address"
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 min-h-[100px]"
                  required
                />

                <div className="space-y-2">
                  <label className="block text-sm font-medium">
                    Annual Fee Plan
                  </label>
                  <select
                    value={annualFee}
                    onChange={(e) => AnnualFee(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="demo">Demo (Free)</option>
                    <option value={1000}>1,000</option>
                    <option value={5000}>5,000</option>
                    <option value={10000}>10,000</option>
                  </select>
                </div>

                <button
                  type="button"
                  onClick={handleInstitutionSignup}
                  disabled={loading}
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? <LoadingSpinner /> : "Institution Sign Up"}
                </button>
              </form>
            )}

            {userType === "student" && (
                  <form className="max-w-md mx-auto space-y-4 p-6">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => Name(e.target.value)}
                    placeholder="Student Name"
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                    required
                    autoFocus
                  />
            
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => Email(e.target.value)}
                    placeholder="Email"
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                    required
                  />
            
                  <div className="relative">
                    <input
                      type={showPass ? "text" : "password"}
                      value={password}
                      onChange={(e) => Password(e.target.value)}
                      placeholder="Password"
                      className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => ShowPass(!showPass)}
                      className="absolute right-3 top-2.5 text-gray-500 hover:text-gray-700"
                    >
                      {showPass ? <EyeOff size={20} /> : <Eye size={20} />}
                    </button>
                  </div>
            
                  <div className="relative">
                    <input
                      type={showConfPass ? "text" : "password"}
                      value={confPassword}
                      onChange={(e) => ConfPassword(e.target.value)}
                      placeholder="Confirm Password"
                      className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => ShowConfPass(!showConfPass)}
                      className="absolute right-3 top-2.5 text-gray-500 hover:text-gray-700"
                    >
                      {showConfPass ? <EyeOff size={20} /> : <Eye size={20} />}
                    </button>
                  </div>
            
                 
                  <input
                    type="tel"
                    value={phone}
                    onChange={(e) => Phone(e.target.value)}
                    placeholder="Phone Number"
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  />
            
                  <input
                    type="text"
                    value={pincode}
                    onChange={(e) => Pincode(e.target.value)}
                    placeholder="Pincode"
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                    required
                  />
            
                  <textarea
                    value={add}
                    onChange={(e) => Add(e.target.value)}
                    placeholder="Address"
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 min-h-[100px]"
                    required
                  />
            
            <button
                  type="button"
                  onClick={handleStudentSignup}
                  disabled={loading}
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? <LoadingSpinner /> : "Student Sign Up"}
                </button>
                </form>
            )}

            <p className="text-center text-sm text-gray-600">
              Already have an Account?{" "}
              <Link to="/tutorlogin" className="text-blue-500 hover:underline">
                Login Now
              </Link>
            </p>
          </div>

          <div />
        </div>

        {userType === "student" && (
          <div
            className="hidden lg:block w-1/2 bg-cover bg-center no-repeat"
            style={{
              backgroundImage: 'url("/images/studentlogin.jpg")',
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
        )}
        {userType === "institution" && (
          <div
            className="hidden lg:block w-1/2 bg-cover bg-center no-repeat"
            style={{
              backgroundImage: 'url("/images/institutionlogin.avif")',
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
        )}
        {userType === "tutor" && (
          <div
            className="hidden lg:block w-1/2 bg-cover bg-center"
            style={{
              backgroundImage: 'url("/images/tutorlogin.webp")',
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
        )}
      </div>
    </>
  );
};

export default RegistrationForm;
