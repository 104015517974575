import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import { ioPort } from '../../../config';

const ChatRoom = () => {
  const apiKey = 'test-key-1';
  const userType = 'tutor';
  const userId = '1';

  const [socket, setSocket] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [participants, setParticipants] = useState([]);
  const [isConnecting, setIsConnecting] = useState(true);
  const [error, setError] = useState(null);
  const scrollAreaRef = useRef(null);

  useEffect(() => {
    const newSocket = io(`http://localhost:${ioPort}`, {
      auth: { apiKey, userType, userId },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    newSocket.on('connect', () => {
      setIsConnecting(false);
      setError(null);
      newSocket.emit('create-room');
    });

    newSocket.on('room-created', ({ roomId }) => {
      setRoomId(roomId);
      newSocket.emit('get-messages', roomId);
    });

    newSocket.on('connect_error', (err) => {
      setError('Connection failed. Please check your API key.');
      setIsConnecting(false);
    });

    newSocket.on('message-history', (history) => {
      setMessages(history);
      scrollToBottom();
    });

    newSocket.on('new-message', (message) => {
      setMessages(prev => [...prev, message]);
      scrollToBottom();
    });

    newSocket.on('user-joined', ({ participants }) => {
      setParticipants(participants);
    });

    newSocket.on('user-left', ({ participants }) => {
      setParticipants(participants);
    });

    newSocket.on('error', (error) => {
      setError(error.message);
    });

    setSocket(newSocket);
    return () => newSocket.close();
  }, [apiKey, userType, userId]);

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !roomId) return;

    socket.emit('send-message', {
      roomId,
      message: newMessage.trim()
    });
    setNewMessage('');
  };

  if (isConnecting) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-4 bg-red-50 text-red-600 rounded-lg">
        {error}
      </div>
    );
  }

  const getUserIcon = (userType) => {
    return userType === 'tutor' ? '👨‍🏫' : '💼';
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      {/* Header with participants */}
      <div className="p-4 bg-gray-50 border-b">
        <div className="flex justify-between items-center mb-2">
          <div className="text-sm font-medium text-gray-600">
            Room ID: {roomId}
          </div>
          <div className="text-sm text-gray-500">
            {participants.length} online
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          {participants.map((participant) => (
            <div 
              key={participant.userId}
              className="text-sm bg-white px-2 py-1 rounded border"
            >
              {getUserIcon(participant.userType)} #{participant.userId.slice(-4)}
            </div>
          ))}
        </div>
      </div>

      {/* Messages area */}
      <div 
        ref={scrollAreaRef}
        className="h-[600px] p-4 overflow-y-auto"
      >
        <div className="space-y-4">
          {messages.map((msg) => (
            <div
              key={msg.id}
              className={`flex ${msg.sender === socket?.id ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`max-w-[70%] rounded-lg px-4 py-2 
                  ${msg.sender === socket?.id 
                    ? 'bg-blue-500 text-white ml-4' 
                    : 'bg-gray-100 mr-4'
                  } 
                  ${msg.userType === 'tutor' 
                    ? 'border-l-4 border-green-500' 
                    : 'border-l-4 border-blue-500'
                  }`}
              >
                <div className="text-xs opacity-75 mb-1">
                  {getUserIcon(msg.userType)} #{msg.userId.slice(-4)}
                </div>
                <div className="break-words">{msg.content}</div>
                <div className="text-xs opacity-75 mt-1">
                  {new Date(msg.timestamp).toLocaleTimeString()}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Message input */}
      <form onSubmit={sendMessage} className="p-4 border-t bg-white">
        <div className="flex space-x-4">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button 
            type="submit"
            disabled={!newMessage.trim()}
            className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 
                     focus:outline-none focus:ring-2 focus:ring-blue-500 
                     disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatRoom;