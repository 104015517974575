import React, { useEffect, useState } from "react";
import { Mail, Phone, MapPin } from "lucide-react";
import { host, mainColor, v } from "../../../config";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { jwtDecode } from "jwt-decode";
import TutorNavbar from "../TutorNavbar/TutorNavbar";

const Resume = () => {
  const [tutorSkills, TutorSkills] = useState([]);
  const [tutorExperiences, TutorExperiences] = useState([]);
  const [tutorAwards, TutorAwards] = useState([]);
  const [tutorAchivements, TutorAchivements] = useState([]);

  const [profile, Profile] = useState({ profile: "" });

  const token = localStorage.getItem("auth");
  const tutorId = jwtDecode(token).tutorId;

  function getData() {
    try {
      fetch(`${host}/api/${v}/tutor/profile_data`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          Profile(data.profile);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  }

  function getSkills() {
    try {
      fetch(`${host}/api/${v}/tutor/skills`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          let skillNames = data.skills.map((skill) => skill.name);
          TutorSkills(skillNames);
          // console.log(skillNames);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  }

  function getExperiences() {
    try {
      fetch(`${host}/api/${v}/tutor/experience`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          TutorExperiences(data.experiences);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  }

  function getAchivement() {
    try {
      fetch(`${host}/api/${v}/tutor/achievement`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          TutorAchivements(data.achivements);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  }

  function getAward() {
    try {
      fetch(`${host}/api/${v}/tutor/award`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          TutorAwards(data.awards);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  }

  useEffect(() => {
    getData();
    getSkills();
    getExperiences();
    getAchivement();
    getAward();
  }, []);

  const generatePDF = async () => {
    const element = document.getElementById("resume");
    if (!element) return;

    try {
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
      });

      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const pdf = new jsPDF({
        orientation: imgHeight > imgWidth ? "portrait" : "landscape",
        unit: "mm",
        format: "a4",
      });

      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(`${profile.name}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <div className="w-full">
      <TutorNavbar />
      <button
        onClick={generatePDF}
        className={`block mx-auto my-8 px-4 py-2 bg-[${mainColor}] text-white rounded hover:bg-blue-600 animate-pulse`}
      >
        Download Resume
      </button>
      <div
        id="resume"
        className={`max-w-4xl mx-auto border-2 max-w-4xl mx-auto border-2 border-[${mainColor}] bg-white rounded p-8 bg-white rounded p-8`}
      >
        {/* Header Section */}
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center">
            <div
              className={`w-32 h-32 rounded-full border-2 border-[${mainColor}] overflow-hidden bg-teal-600`}
            >
              <img
                src={`https://tutorplatter_back.softvisionary.com/tutor_profile/${tutorId}.png`}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="ml-8">
              <h1 className="text-4xl font-bold">{profile.name}</h1>
              <h2 className="text-2xl text-teal-600 mt-2">Tutor</h2>
            </div>
          </div>

          <img
            alt="Tutor Platter"
            className="h-20 mb-4"
            src="https://usercentereddesign.co.in/tutor/images/logo.png"
          />
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-3 gap-8">
          {/* Left Column */}
          <div className="col-span-1">
            {/* Contact */}
            <section className="mb-8">
              <h3 className="text-lg font-bold border-b-2 border-teal-600 pb-2 mb-4">
                CONTACT
              </h3>
              <div className="space-y-3">
                <div className="flex items-center">
                  <Mail size={16} className="mr-2" />
                  <span>{profile.email}</span>
                </div>
                <div className="flex items-center">
                  <Phone size={16} className="mr-2" />
                  <span>{profile.phone1}</span>
                </div>
                <div className="flex items-center">
                  <MapPin size={16} className="mr-2" />
                  <span>
                    <strong>Pincode</strong> : {profile.pincode}
                  </span>
                </div>
              </div>
            </section>

            {/* Profile Summary */}
            <section className="mb-8">
              <h3 className="text-lg font-bold border-b-2 border-teal-600 pb-2 mb-4">
                PROFILE SUMMARY
              </h3>
              <p className="text-sm">
                Business development manager looking to obtain a challenging
                position in a organization, utilizing my proven track record in
                driving revenue growth and forging strategic partnerships to
                achieve business objectives.
              </p>
            </section>

            {/* Skills */}
            <section className="mb-8">
              <h3 className="text-lg font-bold border-b-2 border-teal-600 pb-2 mb-4">
                SKILLS
              </h3>
              <div className="flex flex-wrap gap-2 max-w-full">
                {tutorSkills.map((skill, index) => (
                  <div
                    key={index}
                    className={`bg-green-100 text-gray-800 rounded-lg px-4 py-1 text-sm 
                         cursor-pointer border border-[${mainColor}]`}
                  >
                    {skill}
                  </div>
                ))}
              </div>
            </section>
          </div>

          {/* Right Column */}
          <div className="col-span-2">
            {/* Professional Experience */}
            <section className="mb-8">
              <h3 className="text-lg font-bold border-b-2 border-teal-600 pb-2 mb-4">
                PROFESSIONAL EXPERIENCE
              </h3>

              {tutorExperiences.map((experience, index) => {
                return (
                  <div className="mb-6">
                    <h4 className="font-bold">{experience.name}</h4>
                    <p className="text-gray-600">Tutor</p>
                    <p className="text-sm text-gray-500">
                      NY, USA | 00/0000 - 00/0000
                    </p>
                    <span className="mt-2">{experience.description}</span>
                  </div>
                );
              })}
            </section>

            {/* Education */}
            <section>
              <h3 className="text-lg font-bold border-b-2 border-teal-600 pb-2 mb-4">
                Awards
              </h3>

              {tutorAwards.map((award, index) => {
                return (
                  <React.Fragment>
                    <div className="mb-4">
                      <h4 className="font-bold">{award.name}</h4>
                      <p className="text-gray-600">{award.date}</p>
                      <p className="text-sm text-gray-500">
                        {award.description}
                      </p>
                    </div>
                  </React.Fragment>
                );
              })}
            </section>

            {/* Achivement */}
            <section>
              <h3 className="text-lg font-bold border-b-2 border-teal-600 pb-2 mb-4">
                Achivements
              </h3>

              {tutorAchivements.map((achivement, index) => {
                return (
                  <React.Fragment>
                    <div className="mb-4">
                      <h4 className="font-bold">{achivement.name}</h4>
                      <p className="text-gray-600">{achivement.date}</p>
                      <p className="text-sm text-gray-500">
                        {achivement.description}
                      </p>
                    </div>
                  </React.Fragment>
                );
              })}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
