import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import { host, v } from '../../../config';
import InstitutionNavbar from '../InstitutionNavbar/InstitutionNavbar';

const SearchBySkills = () => {
  const [filters, setFilters] = useState({
    name: '',
    pincode: '',
    city: '',
    skills: ''
  });
  const [tutors, setTutors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTutors = async () => {
    try {
      const response = await fetch(`${host}/api/${v}/institution/all_tutors`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setTutors(data);
      setLoading(false);
    } catch (error) {
      console.error("Error during fetch:", error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTutors();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const filteredTutors = tutors.filter(tutor => {
    const skillsArray = tutor.skills ? tutor.skills.toLowerCase().split(',') : [];
    return (
      (!filters.name || tutor.name.toLowerCase().includes(filters.name.toLowerCase())) &&
      (!filters.pincode || tutor.pincode.toString().includes(filters.pincode)) &&
      (!filters.city || (tutor.city && tutor.city.toLowerCase().includes(filters.city.toLowerCase()))) &&
      (!filters.skills || skillsArray.some(skill =>
        skill.trim().toLowerCase().includes(filters.skills.toLowerCase())))
    );
  });

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading tutors...</div>;
  }

  if (error) {
    return <div className="text-red-600 text-center p-4">Error: {error}</div>;
  }

  return (
    <div className='flex flex-col'>
      <InstitutionNavbar />

      <div className="max-w-7xl mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">Search Tutors</h1>

        <div className="bg-white p-6 rounded-lg shadow-md mb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* <div>
              <label className="block text-sm font-medium mb-2">Name</label>
              <div className="relative">
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                <input
                  type="text"
                  name="name"
                  value={filters.name}
                  onChange={handleFilterChange}
                  className="pl-10 w-full rounded-md border border-gray-300 p-2"
                  placeholder="Search by name..."
                />
              </div>
            </div> */}

            <div>
              <label className="block text-sm font-medium mb-2">Job Title/Skill</label>
              <input
                type="text"
                name="skills"
                value={filters.skills}
                onChange={handleFilterChange}
                className="w-full rounded-md border border-gray-300 p-2"
                placeholder="Search Job Title"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Pincode</label>
              <input
                type="text"
                name="pincode"
                value={filters.pincode}
                onChange={handleFilterChange}
                className="w-full rounded-md border border-gray-300 p-2"
                placeholder="Enter pincode"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">City</label>
              <input
                type="text"
                name="city"
                value={filters.city}
                onChange={handleFilterChange}
                className="w-full rounded-md border border-gray-300 p-2"
                placeholder="Enter city"
              />
            </div>
          </div>
        </div>

        <div className="h-[calc(100vh-350px)] overflow-y-auto scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-400 hover:scrollbar-thumb-gray-500">
          <div className="space-y-6 p-2">
            {filteredTutors.map(tutor => (
              <div key={tutor.tutorId} className="bg-white ring-1 p-6 rounded-lg shadow-md">
                <div className="mb-4">
                  <h2 className="text-xl font-bold">{tutor.name}</h2>
                </div>

                <div className="space-y-2">
                  <p className="text-sm">
                    <strong>City:</strong> {tutor.city || 'Not specified'}
                  </p>
                  <p className="text-sm">
                    <strong>Pincode:</strong> {tutor.pincode}
                  </p>
                  <div className="mt-3 flex flex-wrap gap-2">
                    <strong className="text-sm">Skills:</strong>
                    {tutor.skills.split(',').map((skill, index) => (
                      <span
                        key={index}
                        className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full"
                      >
                        {skill.trim()}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>

  );
};

export default SearchBySkills;