import React, { useState } from "react";
import Navbar from "../../../CommonWidget/Navbar/Navbar";
import {
  Box,
  Button,
  Container,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SizedBox from "../../../Widgets/SizedBox";
import LoadingSpinner from "../../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from "../../../../config";
import { Link, useNavigate } from "react-router-dom";

const TutorRegister = () => {
  const navigate = useNavigate();

  const [loading, Loading] = useState(false);
  const [name, Name] = useState("");
  const [email, Email] = useState("");
  const [pincode, Pincode] = useState("");
  const [pref, Pref] = useState("Both");
  const [password, Password] = useState("");
  const [confPassword, ConfPassword] = useState("");

  const [showPass, ShowPass] = useState(false);
  const [showConfPass, ShowConfPass] = useState(false);

  const [showModal, ShowModal] = useState(false);

  function handleSignup() {
    if (
      !(
        name.trim().length &&
        email.trim().length &&
        password.trim().length &&
        confPassword.trim().length &&
        pincode.trim().length
      )
    ) {
      alert("Please fill All Details");
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Please Enter a Valid Email address");
      return;
    }

    if (password.trim().length < 6) {
      alert("Passwords must be at least 6 characters");
      return;
    }
    if (!(/[a-z]/.test(password.trim()) && /[A-Z]/.test(password.trim()))) {
      alert("Password must have a Small Case & Upper Case Alphabet");
      return;
    }

    if (password != confPassword) {
      alert("Passwords do not Match");
      return;
    }

    Loading(true);

    fetch(`${host}/api/${v}/visitor/tutorregister`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: name,
        email: email,
        pincode: pincode,
        teaching_pref: pref,
        password: password,
      }),
    })
      .then((response) => {
        if (response.status === 400) {
          // handleClick({ vertical: "top", horizontal: "right" });
        }
        if (!response.ok) {
          if (response.status === 403) {
            alert("Email Already Exist!");
          }
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === true) {
          ShowModal(true);
        }
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <React.Fragment>
      <Navbar />
      <Modal
        open={showModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 200 }}>
          <h2 id="child-modal-title">Registration Successfull</h2>
          <p id="child-modal-description">Please Login as Tutor</p>
          <Button
            onClick={() => {
              ShowModal(false);
              navigate("/tutorlogin");
            }}
            variant="contained"
          >
            Login Now
          </Button>
        </Box>
      </Modal>

      <div>
        <Container className={`XCon`} component="main" maxWidth="xs">
          <h3>Let's find you a job you'll love</h3>
          Sign up to instantly apply for jobs anywhere.
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                value={name}
                onChange={(v) => Name(v.target.value)}
                autoFocus
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(v) => Email(v.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Pincode"
                name="email"
                autoComplete="email"
                value={pincode}
                onChange={(v) => Pincode(v.target.value)}
              />

              <div className="left">Teaching Prefrence</div>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pref}
                onChange={(v) => Pref(v.target.value)}
              >
                <MenuItem value={"Private"}>Online</MenuItem>
                <MenuItem value={"Government"}>Offline</MenuItem>
                <MenuItem value={"Both"}>Both</MenuItem>
              </Select>

              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                type={showPass ? "text" : "password"}
                name="password"
                value={password}
                onChange={(v) => Password(v.target.value)}
                autoComplete="true"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => ShowPass(!showPass)}
                        edge="end"
                      >
                        {showConfPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Confirm Password"
                type={showConfPass ? "text" : "password"}
                name="confirm password"
                value={confPassword}
                onChange={(e) => ConfPassword(e.target.value)}
                autoComplete="true"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => ShowConfPass(!showConfPass)}
                        edge="end"
                      >
                        {showConfPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Link to="/tutorlogin">
                <Button>Already have an Account?</Button>
              </Link>
              <br />
              {loading ? (
                <LoadingSpinner />
              ) : (
                <Button onClick={() => handleSignup()} variant="contained">
                  SignUp
                </Button>
              )}
              <SizedBox height="40px" />
            </FormControl>
          </Box>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TutorRegister;
