import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InstitutionNavbar from "../InstitutionNavbar/InstitutionNavbar";
import { Button } from "@mui/material";
import styles from "./styles.module.css";
import SizedBox from "../../CommonWidget/SizedBox/SizedBox";
import { host, v } from "../../../config";

const JobApplicantProfile = () => {
  const navigate = useNavigate();

  const [profileImage, setProfileImage] = useState(
    `${host}/tutor_profile/user__11.png`
  );

  function navBack(){
    navigate(-2);
  }



  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 12,
    p: 4,
  };
  return (
    <React.Fragment>
      <InstitutionNavbar title="Profile" />
      <div className={styles.body}>
       <Button onClick={()=>navBack()}>Back</Button>
        <div className={` ${styles.card}`}>
          <div className={styles.flex}>
            <div className={styles.left}>
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  className={styles.avatar}
                />
              ) : (
                <div className={styles.avatar}>No Profile Image</div>
              )}
              <div>name here</div>
              <SizedBox height="20px" />
              <h2>Personal Details</h2>
              <hr />
              <ul>
              <li>
                <image
                  xmlns="http://www.w3.org/2000/svg"
                  x="15"
                  y="147.4125"
                  width="15"
                  height="15"
                  href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiMwMTNiNjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PHBhdGggZD0iTTUwMi4zIDE5MC44YzMuOS0zLjEgOS43LS4yIDkuNyA0LjdWNDAwYzAgMjYuNS0yMS41IDQ4LTQ4IDQ4SDQ4Yy0yNi41IDAtNDgtMjEuNS00OC00OFYxOTUuNmMwLTUgNS43LTcuOCA5LjctNC43IDIyLjQgMTcuNCA1Mi4xIDM5LjUgMTU0LjEgMTEzLjYgMjEuMSAxNS40IDU2LjcgNDcuOCA5Mi4yIDQ3LjYgMzUuNy4zIDcyLTMyLjggOTIuMy00Ny42IDEwMi03NC4xIDEzMS42LTk2LjMgMTU0LTExMy43ek0yNTYgMzIwYzIzLjIuNCA1Ni42LTI5LjIgNzMuNC00MS40IDEzMi43LTk2LjMgMTQyLjgtMTA0LjcgMTczLjQtMTI4LjcgNS44LTQuNSA5LjItMTEuNSA5LjItMTguOXYtMTljMC0yNi41LTIxLjUtNDgtNDgtNDhINDhDMjEuNSA2NCAwIDg1LjUgMCAxMTJ2MTljMCA3LjQgMy40IDE0LjMgOS4yIDE4LjkgMzAuNiAyMy45IDQwLjcgMzIuNCAxNzMuNCAxMjguNyAxNi44IDEyLjIgNTAuMiA0MS44IDczLjQgNDEuNHoiPjwvcGF0aD48L3N2Zz4="
                  image-rendering="optimizeSpeed"
                />
                email here
              </li>
              <li>phone Here</li>
              <li>Address Here</li>
              <li>pincode Here</li>
              </ul>

              <h2>Skills</h2>
              <hr />
              <ul>
                <li>Leadership</li>
                <li>Organization</li>
                <li>Leadership</li>
                <li>Organization</li>
                <li>Problem</li>
                <li>Solving</li>
                <li>Planning</li>
                <li>Management</li>
                <li>Delegation</li>
              </ul>
            </div>

            <div className={styles.right}>
              <h1>Summary</h1>
              <hr />
              <p>
                Organized and efficient project manager with 11 + years of
                experience managing large - scale cloud computing and IT -
                related projects . Seeking to leverage leadership experience and
                in - depth cloud computing knowledge to join Amazon as an IT
                Project Manager .
              </p>
              <h1>Work Experience</h1>
              <hr />
              <div className={styles.flex}>
                <h5>Drawing Teacher till 5th Class</h5>
                <div className={styles.dt}>2006-2010</div>
              </div>
              <h1>Education</h1>
              <hr />
              <div className={styles.flex}>
                <h5>Bachelor of Science in Business Administration</h5>
                <div className={styles.dt}>2006-2010</div>
              </div>

              <h1>Certifications</h1>
              <hr />
              <div className={styles.flex}>
                <h5>Certified Project Manager</h5>
                <div className={styles.dt}>Present</div>
              </div>
              <div class={styles.desc}>
                Awarded by the Project Management Institute
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobApplicantProfile;
