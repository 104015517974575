import React, { useEffect, useRef, useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import app from "../../Uiities/firebaseConfig";
import TutorNavbar from "../TutorNavbar/TutorNavbar";
import { host, v } from "../../../config";
import { jwtDecode } from "jwt-decode";
import { DateField } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CreatableSelect from "react-select/creatable";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Trash2, Share2Icon, Trash } from "lucide-react";
import { countriesList } from "../../Uiities/countriesData";

const TutorProfile = () => {
  const [editPersonalDetails, setEditPersonalDetails] = useState(false);
  const inputRef = useRef(null);
  const [skillAddLoading, setSkillAddLoading] = useState(false);
  const [newSkill, setNewSkill] = useState("");
  const [openSkillModal, setOpenSkillModal] = useState(false);
  const [experienceLoading, setExperienceLoading] = useState(false);
  const [newExperienceTitle, setNewExperienceTitle] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [newExperienceDescription, setNewExperienceDescription] = useState("");
  const [openExperienceModal, setOpenExperienceModal] = useState(false);
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    phone1: "",
    maritalStatus: "",
    languages: "",
    gender: "",
    dob: "",
    motherTongue: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    videoUrl: "",
  });
  const [profileImage, setProfileImage] = useState(null);
  const [openProfileVideoModal, setProfileModalUrl] = useState(false);
  const [resumePdf, setResumePdf] = useState([]);

  const [tutorSkills, TutorSkills] = useState([]);
  const [selectedLanguages, SelectedLanguages] = useState([]);
  const [tutorExperiences, setTutorExperiences] = useState([]);
  const [tutorAwards, setTutorAwards] = useState([]);
  const [tutorAchievements, setTutorAchievements] = useState([]);
  const [awardLoading, setAwardLoading] = useState(false);
  const [achievementLoading, setAchievementLoading] = useState(false);
  const [openAwardModal, setOpenAwardModal] = useState(false);
  const [openAchievementModal, setOpenAchievementModal] = useState(false);
  const [newAwardTitle, setNewAwardTitle] = useState("");
  const [newAchievementTitle, setNewAchievementTitle] = useState("");
  const [awardDate, setAwardDate] = useState("");
  const [achievementDate, setAchievementDate] = useState("");
  const [newAwardDescription, setNewAwardDescription] = useState("");
  const [newAchievementDescription, setNewAchievementDescription] =
    useState("");
  const [availability, setAvailability] = useState("yes");
  const [reportBugModal, setReportBugModal] = useState(false);

  // Add these functions
  const addNewAward = async () => {
    try {
      setAwardLoading(true);
      const response = await fetch(`${host}/api/${v}/tutor/award`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
        body: JSON.stringify({
          name: newAwardTitle,
          date: awardDate,
          description: newAwardDescription,
        }),
      });

      if (response.ok) {
        setOpenAwardModal(false);
        await getAwards();
        setNewAwardTitle("");
        setAwardDate("");
        setNewAwardDescription("");
      }
    } catch (error) {
      console.error("Error adding award:", error);
    } finally {
      setAwardLoading(false);
    }
  };

  function getSkills() {
    try {
      fetch(`${host}/api/${v}/tutor/skills`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          let skillNames = data.skills.map((skill) => skill.name);
          TutorSkills(skillNames);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  }

  const addNewAchievement = async () => {
    try {
      setAchievementLoading(true);
      const response = await fetch(`${host}/api/${v}/tutor/achievement`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
        body: JSON.stringify({
          name: newAchievementTitle,
          date: achievementDate,
          description: newAchievementDescription,
        }),
      });

      if (response.ok) {
        setOpenAchievementModal(false);
        await getAchievements();
        setNewAchievementTitle("");
        setAchievementDate("");
        setNewAchievementDescription("");
      }
    } catch (error) {
      console.error("Error adding achievement:", error);
    } finally {
      setAchievementLoading(false);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("profileImage", file);

    try {
      const response = await fetch(
        `${host}/api/${v}/tutor/upload_profile_img`,
        {
          method: "POST",
          headers: { Authorization: `Bearer ${localStorage.getItem("auth")}` },
          body: formData,
        }
      );

      const data = await response.json();

      if (response.ok) {
        alert("Image uploaded successfully!");
        const imageUrl = URL.createObjectURL(file);
        setProfileImage(imageUrl);
      } else {
        alert(data.error || "Failed to upload image");
      }
    } catch (error) {
      alert("Error uploading image. Please try again.");
    }
  };

  const handlePersonalDetail = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLanguages = (e) => {
    SelectedLanguages(e);
    const languages = e.map((item) => item.label);
    setProfile((prev) => ({
      ...prev,
      ["languages"]: languages,
    }));
  };

  const handleResumeUpload = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.type === "application/pdf") {
        setResumePdf(selectedFile);
        alert("Uploading Resume");

        const formData = new FormData();
        formData.append("pdfResume", selectedFile);

        try {
          const response = await fetch(
            `${host}/api/${v}/tutor/upload_profile_resume`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`,
              },
              body: formData,
            }
          );

          const data = await response.json();

          if (response.ok) {
            alert("Resume uploaded successfully!");
          } else {
            alert(data.error || "Failed to upload Resume");
          }
        } catch (error) {
          alert("Error uploading image. Please try again.");
        }
      } else {
        alert("Please upload a PDF file");
        setResumePdf(null);
      }
    }
  };

  // Functions for handling data fetching
  const getData = async () => {
    try {
      const token = localStorage.getItem("auth");
      const tutorId = jwtDecode(token).tutorId;
      setProfileImage(`${host}/tutor_profile/${tutorId}.png`);

      const response = await fetch(`${host}/api/${v}/tutor/profile_data`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const apiResponse = await response.json();
        setProfile(apiResponse.profile);

        const lang = apiResponse.profile.languages.map((lang) => ({
          label: lang,
          value: lang,
          __isNew__: true,
        }));
        SelectedLanguages(lang);
        // {label: 'sd', value: 'sd', __isNew__: true}
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getExperiences = async () => {
    try {
      const response = await fetch(`${host}/api/${v}/tutor/experience`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setTutorExperiences(data.experiences);
      }
    } catch (error) {
      console.error("Error fetching experiences:", error);
    }
  };

  const deleteExperience = async (experienceId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this experience?"
    );

    if (!confirmDelete) return;
    try {
      const response = await fetch(
        `${host}/api/${v}/tutor/experience/${experienceId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        }
      );

      if (response.ok) {
        setTutorExperiences((prev) =>
          prev.filter((exp) => exp.experienceId !== experienceId)
        );
        alert("Experience deleted successfully");
      } else {
        alert("Failed to delete experience");
      }
    } catch (error) {
      console.error("Error deleting experience:", error);
      alert("Error deleting experience");
    }
  };

  const addNewExperience = async () => {
    try {
      setExperienceLoading(true);
      const response = await fetch(`${host}/api/${v}/tutor/experience`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
        body: JSON.stringify({
          name: newExperienceTitle,
          fromDate,
          toDate,
          description: newExperienceDescription,
        }),
      });

      if (response.ok) {
        setOpenExperienceModal(false);
        await getExperiences();
        // Reset form
        setNewExperienceTitle("");
        setFromDate("");
        setToDate("");
        setNewExperienceDescription("");
      }
    } catch (error) {
      console.error("Error adding experience:", error);
    } finally {
      setExperienceLoading(false);
    }
  };

  const getAwards = async () => {
    try {
      const response = await fetch(`${host}/api/${v}/tutor/award`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setTutorAwards(data.awards);
      }
    } catch (error) {
      console.error("Error fetching awards:", error);
    }
  };

  const deleteAward = async (awardId) => {
    if (!window.confirm("Delete this award?")) return;

    try {
      const response = await fetch(`${host}/api/${v}/tutor/award/${awardId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
        body: JSON.stringify({ tutorId: profile.tutorId }),
      });

      if (response.ok) {
        setTutorAwards((prev) =>
          prev.filter((award) => award.awardId !== awardId)
        );
        alert("Award deleted successfully");
      } else {
        alert("Failed to delete award");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error deleting award");
    }
  };

  const getAchievements = async () => {
    try {
      const response = await fetch(`${host}/api/${v}/tutor/achievement`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setTutorAchievements(data.achivements);
      }
    } catch (error) {
      console.error("Error fetching achievements:", error);
    }
  };

  const deleteAchievement = async (achievementId) => {
    if (!window.confirm("Delete this achievement?")) return;

    try {
      const response = await fetch(
        `${host}/api/${v}/tutor/achievement/${achievementId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
          body: JSON.stringify({ tutorId: profile.tutorId }),
        }
      );

      if (response.ok) {
        setTutorAchievements((prev) =>
          prev.filter((a) => a.value !== achievementId)
        );
        alert("Achievement deleted successfully");
        getAchievements();
      } else {
        alert("Failed to delete achievement");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error deleting achievement");
    }
  };

  useEffect(() => {
    getData();
    getExperiences();
    getSkills();
    getAwards();
    getAchievements();
  }, []);

  const openResume = () => {
    window.open("/tutor/resume");
  };

  const updateProfile = async () => {
    try {
      const response = await fetch(`${host}/api/${v}/tutor/profile_data`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
        body: JSON.stringify({ profile }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      alert("Profile Updated Succefully");

      setEditPersonalDetails(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  };

  const addNewSkill = () => {
    try {
      setSkillAddLoading(true);
      fetch(`${host}/api/${v}/tutor/skills`, {
        method: "POST",
        body: JSON.stringify({ name: newSkill }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          setNewSkill("");
          setSkillAddLoading(false);
          setOpenSkillModal(false);

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          getSkills();
          return response.json();
        })
        .then((data) => {
          // Profile(data.profile);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  };

  const deleteSkill = (skill) => {
    try {
      fetch(`${host}/api/${v}/tutor/skills/${skill}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const newSkills = tutorSkills.filter((element) => element !== skill);
          TutorSkills(newSkills);
          alert('Your ' + skill + ' skill delete success')
        })
        .catch((error) => {
          console.error("Error deleting skill:", error);
        });
    } catch {}
  };

  const handleVideo = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1];

    const { tutorId } = jwtDecode(token);
    if (!tutorId) {
      alert("Please ReLogin First");
      return;
    }

    const storage = getStorage(app);
    const videoRef = ref(storage, `Tutor/teaching_videos/${tutorId}`);

    try {
      const snapshot = await uploadBytes(videoRef, file);
      const url = await getDownloadURL(snapshot.ref);
      const newProfile = profile;
      newProfile.videoUrl = url;

      const result = await updateProfile(newProfile);
      if (result.ok) setProfile(newProfile);
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  const copyProfileUrl = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/tutor_profile/${profile.emailVerifyUrl}`
    );
  };

  async function handleUpdateAvailabe(e) {
    setProfile((prev) => ({
      ...prev,
      availability: e.target.value,
    }));

    const response = await fetch(`${host}/api/${v}/tutor/availablity`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify({ availability: e.target.value }),
    });

    if (response.ok) {
      alert("Updated Availablity Status Successfully");
    } else {
      alert(response.error || "Failed to update availability");
    }
  }

  const removeVideo = async () => {
    try {
      alert("Removing your Video");
      const newProfile = profile;
      newProfile.videoUrl = "";

      await updateProfile(newProfile);
      setProfile(newProfile);
    } catch {
      alert("Error removing the video");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navbar */}
      <TutorNavbar />

      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {openAwardModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-medium">Add New Award</h3>
                <button
                  onClick={() => setOpenAwardModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </div>
              <input
                type="text"
                value={newAwardTitle}
                onChange={(e) => setNewAwardTitle(e.target.value)}
                className="w-full p-2 border rounded-md mb-4"
                placeholder="Award Title"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  label="Award Date"
                  value={awardDate}
                  onChange={(newValue) => {
                    let formattedDate = dayjs(newValue).format("DDMMMYYYY");
                    setAwardDate(formattedDate);
                  }}
                  className="w-full mb-4"
                />
              </LocalizationProvider>
              <textarea
                value={newAwardDescription}
                onChange={(e) => setNewAwardDescription(e.target.value)}
                className="w-full p-2 border rounded-md mb-4"
                rows="4"
                placeholder="Award Description"
              />
              <button
                onClick={addNewAward}
                disabled={awardLoading}
                className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 disabled:bg-blue-300"
              >
                {awardLoading ? "Adding..." : "Add Award"}
              </button>
            </div>
          </div>
        )}

        {/* Skill Modal */}
        {openSkillModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-medium">Add New Skill</h3>
                <button
                  onClick={() => setOpenSkillModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </div>
              <input
                type="text"
                value={newSkill}
                onChange={(e) => setNewSkill(e.target.value)}
                className="w-full p-2 border rounded-md mb-4"
                placeholder="Enter skill name"
              />
              <button
                onClick={() => {
                  // TutorSkills((prev) => [...prev, newSkill]);
                  addNewSkill();
                }}
                className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700"
                disabled={skillAddLoading}
              >
                {skillAddLoading ? "Adding..." : "Add Skill"}
              </button>
            </div>
          </div>
        )}

        {/* Experience Modal */}
        {openExperienceModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-lg">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-medium">Add Experience</h3>
                <button
                  onClick={() => setOpenExperienceModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Institution Name
                  </label>
                  <input
                    type="text"
                    value={newExperienceTitle}
                    onChange={(e) => setNewExperienceTitle(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter institution name"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label="Joining Date"
                      value={fromDate}
                      onChange={(newValue) => {
                        let formattedDate = dayjs(newValue).format("DDMMMYYYY");
                        setFromDate(formattedDate);
                      }}
                    />
                    <DateField
                      label="Leaving Date"
                      value={toDate}
                      onChange={(newValue) => {
                        let formattedDate = dayjs(newValue).format("DDMMMYYYY");
                        setToDate(formattedDate);
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Description
                  </label>
                  <textarea
                    value={newExperienceDescription}
                    onChange={(e) =>
                      setNewExperienceDescription(e.target.value)
                    }
                    rows="4"
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter job description"
                  />
                </div>

                <button
                  onClick={addNewExperience}
                  disabled={experienceLoading}
                  className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 disabled:bg-blue-300"
                >
                  {experienceLoading ? "Adding..." : "Add Experience"}
                </button>
              </div>
            </div>
          </div>
        )}

        {openAchievementModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-lg">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-medium">Add Achivement</h3>
                <button
                  onClick={() => setOpenAchievementModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  X
                </button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Achivement Name
                  </label>
                  <input
                    type="text"
                    value={newAchievementTitle}
                    onChange={(e) => setNewAchievementTitle(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter institution name"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Description
                  </label>
                  <textarea
                    value={newAchievementDescription}
                    onChange={(e) =>
                      setNewAchievementDescription(e.target.value)
                    }
                    rows="4"
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter job description"
                  />
                </div>

                <button
                  onClick={addNewAchievement}
                  disabled={achievementLoading}
                  className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 disabled:bg-blue-300"
                >
                  {achievementLoading ? "Adding..." : "Add Achivemnet"}
                </button>
              </div>
            </div>
          </div>
        )}

        {openProfileVideoModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-lg">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-medium">Upload Your Video</h3>
                <button
                  onClick={() => setProfileModalUrl(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  X
                </button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Video URL
                  </label>
                  <input
                    type="text"
                    value={profile.videoUrl}
                    onChange={(e) =>
                      setProfile((prev) => ({
                        ...prev,
                        videoUrl: e.target.value,
                      }))
                    }
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter institution name"
                  />
                </div>

                <div className="text-2xl font-bold">OR</div>
                <div>
                  <div className="block text-sm font-medium text-gray-700 mb-1">
                    Drop your Vide here (max. Size : 40MB)
                  </div>
                  <input type="file" accept="video/*" onChange={handleVideo} />
                </div>

                <button
                  onClick={() => {
                    updateProfile();
                    setProfileModalUrl(false);
                  }}
                  className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 disabled:bg-blue-300"
                >
                  {achievementLoading ? "Modifing..." : "Modify"}
                </button>
              </div>
            </div>
          </div>
        )}

        {reportBugModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-medium">Report a Bug</h3>
                <button
                  onClick={() => setReportBugModal(false)}
                  className="text-gray-500 hover:text-gray-700 text-2xl"
                >
                  ×
                </button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Description
                  </label>
                  <textarea
                    //  value={bugDescription}
                    //  onChange={(e) => setBugDescription(e.target.value)}
                    className="w-full p-2 border rounded-md h-32 resize-none"
                    placeholder="Please describe the issue you encountered..."
                  />
                </div>

                <button
                  //  onClick={handleSubmit}
                  className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 disabled:bg-blue-400"
                  //  disabled={!bugDescription || isSubmitting}
                >
                  {false ? "Submitting..." : "Submit Bug Report"}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col lg:flex-row gap-6">
          {/* Left Sidebar */}
          <div className="w-full lg:w-1/4 space-y-6">
            {/* Profile Card */}
            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-medium">Profile</h2>
                <button onClick={copyProfileUrl} className="text-blue-600">
                  <Share2Icon className="text-blue-600" />
                </button>
              </div>
              <div className="flex justify-center mb-4">
                {profileImage ? (
                  <img
                    src={profileImage}
                    alt="Profile"
                    className="w-32 h-32 rounded-full object-cover border-4 border-green-400"
                  />
                ) : (
                  <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center border-4 border-green-400">
                    <span className="text-gray-500">No Image</span>
                  </div>
                )}
              </div>
              <button
                onClick={handleClick}
                className="w-full bg-gray-200 text-gray-900 py-2 rounded-lg hover:bg-gray-300 transition-colors"
              >
                Upload new profile
              </button>
              <input
                type="file"
                ref={inputRef}
                className="hidden"
                onChange={handleImageChange}
                accept="image/*"
              />
            </div>

            {/* Availability Card */}
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-medium mb-4">Available for Hire</h2>
              <div className="grid grid-cols-2 gap-2">
                <label className="p-4 bg-green-100 rounded-lg cursor-pointer hover:bg-green-200">
                  <input
                    type="radio"
                    name="availability"
                    value="yes"
                    className="mr-2"
                    checked={profile.availability === "yes"}
                    onChange={handleUpdateAvailabe}
                  />
                  <span>Yes</span>
                </label>

                <label className="p-4 bg-red-100 rounded-lg cursor-pointer hover:bg-red-200">
                  <input
                    type="radio"
                    name="availability"
                    value="no"
                    className="mr-2"
                    checked={profile.availability === "no"}
                    onChange={handleUpdateAvailabe}
                  />
                  <span>No</span>
                </label>
              </div>
            </div>

            {/* Skills Card */}
            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-medium">Skills</h2>
                <button
                  onClick={() => setOpenSkillModal(true)}
                  className="text-blue-600 hover:text-blue-700"
                >
                  Add New
                </button>
              </div>
              <div className="flex flex-wrap gap-2 space-y-2">
                {tutorSkills.map((skill, index) => (
                  <span
                    key={index}
                    className="px-4 py-2 bg-green-100 border border-green-200 rounded flex items-center gap-2"
                  >
                    {skill}
                    <button
                      onClick={() => deleteSkill(skill)}
                      className="hover:text-red-600"
                    >
                      <Trash2 size={16} />
                    </button>
                  </span>
                ))}
              </div>
            </div>

            {/* Video Upload Card */}
            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-medium">
                  Upload a demo teaching video
                </h2>

                <div className="flex flex-col gap-2">
                  <button
                    onClick={() => setProfileModalUrl(true)}
                    className="text-blue-600 hover:font-bold"
                  >
                    Upload
                  </button>
                  {profile.videoUrl && (
                    <button
                      onClick={() => removeVideo()}
                      className="text-red-600 hover:text-red-900"
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
              <div className="aspect-video bg-gray-100 rounded-lg flex items-center justify-center">
                {profile.videoUrl ? (
                  <video
                    controls
                    width="100%"
                    height="auto"
                    className="rounded-lg shadow-lg"
                  >
                    <source src={profile.videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <span className="text-gray-500">No video uploaded</span>
                )}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={() => setReportBugModal(true)}
                className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
              >
                Report a Bug
              </button>
            </div>
          </div>

          {/* Main Content */}
          <div className="w-full lg:w-3/4">
            {/* Personal Details */}
            <div className="bg-white rounded-lg shadow p-6 mb-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-medium">Personal Details</h2>
                <div className="flex gap-4">
                  <button
                    onClick={openResume}
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg"
                  >
                    Generate Resume Now
                  </button>
                  <div className="relative">
                    <input
                      type="file"
                      onChange={handleResumeUpload}
                      accept=".pdf"
                      className="absolute inset-0 opacity-0 w-full h-full"
                    />
                    <button className="bg-green-600 text-white px-4 py-2 rounded-lg w-full cursor-pointer">
                      Upload Resume
                    </button>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={profile.name}
                    onChange={handlePersonalDetail}
                    disabled={!editPersonalDetails}
                    className="w-full p-2 border rounded-md bg-white"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    name="dob"
                    value={profile.dob}
                    onChange={handlePersonalDetail}
                    disabled={!editPersonalDetails}
                    max={new Date().toISOString().split("T")[0]}
                    className="w-full p-2 border rounded-md bg-white"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={profile.email}
                    disabled="true"
                    className="w-full p-2 border rounded-md bg-white"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone1"
                    value={profile.phone1}
                    onChange={handlePersonalDetail}
                    disabled={!editPersonalDetails}
                    className="w-full p-2 border rounded-md bg-white"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Marital Status
                  </label>
                  <select
                    name="maritalStatus"
                    value={profile.maritalStatus}
                    onChange={handlePersonalDetail}
                    disabled={!editPersonalDetails}
                    className="w-full p-2 border rounded-md bg-white"
                  >
                    <option value="">Select Marital Status</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorced">Divorced</option>
                    <option value="widowed">Widowed</option>
                    <option value="separated">Separated</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Languages Known
                  </label>

                  <CreatableSelect
                    isMulti
                    options={[]}
                    value={selectedLanguages}
                    name="languages"
                    onChange={handleLanguages}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Gender
                  </label>
                  <select
                    name="gender"
                    value={profile.gender}
                    onChange={handlePersonalDetail}
                    disabled={!editPersonalDetails}
                    className="w-full p-2 border rounded-md bg-white"
                  >
                    <option value="">Select your Gender</option>
                    <option value="male">Male</option>
                    <option value="female">FeMale</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Mother Tongue
                  </label>
                  <input
                    type="text"
                    name="motherTongue"
                    value={profile.motherTongue}
                    onChange={handlePersonalDetail}
                    disabled={!editPersonalDetails}
                    className="w-full p-2 border rounded-md bg-white"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={profile.city}
                    onChange={handlePersonalDetail}
                    disabled={!editPersonalDetails}
                    className="w-full p-2 border rounded-md bg-white"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    State
                  </label>
                  <input
                    type="text"
                    name="state"
                    value={profile.state}
                    onChange={handlePersonalDetail}
                    disabled={!editPersonalDetails}
                    className="w-full p-2 border rounded-md bg-white"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Pincode
                  </label>
                  <input
                    type="text"
                    name="pincode"
                    maxLength="6"
                    value={profile.pincode}
                    onChange={handlePersonalDetail}
                    disabled={!editPersonalDetails}
                    className="w-full p-2 border rounded-md bg-white"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Country
                  </label>
                  <select
                    name="country"
                    value={profile.country}
                    onChange={handlePersonalDetail}
                    disabled={!editPersonalDetails}
                    className="w-full p-2 border rounded-md bg-white"
                  >
                    <option value="">Select a country</option>
                    {countriesList.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {editPersonalDetails ? (
                <div className="flex gap-4 mt-6">
                  <button
                    onClick={() => setEditPersonalDetails(false)}
                    className="bg-red-600 text-white px-4 py-2 rounded-lg"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={updateProfile}
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setEditPersonalDetails(true)}
                  className="mt-6 bg-blue-600 text-white px-4 py-2 rounded-lg"
                >
                  Edit
                </button>
              )}
            </div>

            {/* Experiences Section */}
            <div className="bg-white rounded-lg shadow p-6 mb-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-medium">Experience</h2>
                <button
                  onClick={() => setOpenExperienceModal(true)}
                  className="text-blue-600 hover:text-blue-700"
                >
                  Add Experience
                </button>
              </div>
              {tutorExperiences.length === 0 ? (
                <p className="text-gray-500">No experiences added yet!</p>
              ) : (
                <div className="space-y-4">
                  {tutorExperiences.map((exp, index) => (
                    <div key={index} className="border rounded-lg p-4">
                      <div className="flex justify-between">
                        <h3 className="font-medium">{exp.name}</h3>
                        <span className="flex text-gray-500">
                          {exp.fromDate} - {exp.toDate}
                          <Trash
                            onClick={() => deleteExperience(exp.experienceId)}
                            className="ml-2 text-red-500"
                          />
                        </span>
                      </div>
                      <p className="mt-2 text-gray-600">{exp.description}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Awards Section */}
            <div className="bg-white rounded-lg shadow p-6 mb-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-medium">Awards</h2>
                <button
                  onClick={() => setOpenAwardModal(true)}
                  className="text-blue-600 hover:text-blue-700"
                >
                  Add Award
                </button>
              </div>
              {tutorAwards.length === 0 ? (
                <p className="text-gray-500">No awards added yet!</p>
              ) : (
                <div className="space-y-4">
                  {tutorAwards.map((award, index) => (
                    <div key={index} className="border rounded-lg p-4">
                      <div className="flex justify-between">
                        <h3 className="font-medium">{award.name}</h3>
                        <span className="text-gray-500">
                          {award.date}
                          <Trash
                            onClick={() => deleteAward(award.awardId)}
                            className="ml-2 text-red-500"
                          />
                        </span>
                      </div>
                      <p className="mt-2 text-gray-600">{award.description}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Achievements Section */}
            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-medium">Achievements</h2>
                <button
                  onClick={() => setOpenAchievementModal(true)}
                  className="text-blue-600 hover:text-blue-700"
                >
                  Add Achievement
                </button>
              </div>
              {tutorAchievements.length === 0 ? (
                <p className="text-gray-500">No achievements added yet!</p>
              ) : (
                <div className="space-y-4">
                  {tutorAchievements.map((achievement, index) => (
                    <div key={index} className="border rounded-lg p-4">
                      <div className="flex justify-between">
                        <h3 className="font-medium">{achievement.name}</h3>
                        <span className="text-gray-500">
                          {achievement.date}
                          <Trash
                            onClick={() =>
                              deleteAchievement(achievement.achivementId)
                            }
                            className="ml-2 text-red-500"
                          />
                        </span>
                      </div>
                      <p className="mt-2 text-gray-600">
                        {achievement.description}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorProfile;
