import React, { useState, useEffect } from 'react';
import InstitutionNavbar from '../InstitutionNavbar/InstitutionNavbar';
import axios from 'axios';
import Chat from './Chat';
import { jwtDecode } from "jwt-decode";
import { ioPort } from '../../../config';

const EmployerMessages = () => {
    const token = localStorage.getItem("auth");
    const { institutionId } = jwtDecode(token);

    const [chatList, setChatList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedParticipant, setSelectedParticipant] = useState(null)

    useEffect(() => {
        const fetchChatList = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`http://localhost:${ioPort}/chat-list/e${institutionId}`);
                setChatList(response.data);
            } catch (error) {
                console.error('Error fetching chat list:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchChatList();
    }, []);


    return (
        <div className="min-h-screen bg-gray-50">
            <InstitutionNavbar />

            {loading ? (
                <div className="flex justify-center items-center h-screen bg-gray-50">
                    <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
                </div>
            ) : (
                <div className="max-w-8xl mx-auto px-4 py-6">
                    <div className="flex gap-6">
                        {/* Sidebar */}
                        <div className="w-96 bg-white rounded-2xl shadow-lg p-6 h-[calc(100vh-12rem)]">
                            <h2 className="text-2xl font-bold text-gray-800 mb-6">Recent Chats</h2>

                            <div className="space-y-3 overflow-y-auto h-[calc(100%-4rem)] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                                {chatList.length === 0 ? (
                                    <div className="flex flex-col items-center justify-center h-full text-gray-500">
                                        <svg className="w-16 h-16 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                        </svg>
                                        <p className="text-lg font-medium">No conversations yet</p>
                                        <p className="text-sm">Start chatting with tutors</p>
                                    </div>
                                ) : (
                                    chatList.map(({ tutorId, lastMessage, unreadCount }) => (
                                        <div
                                            key={tutorId}
                                            onClick={() => setSelectedParticipant(tutorId)}
                                            className={`group rounded-xl p-4 transition-all duration-200 cursor-pointer
                        ${selectedParticipant === tutorId
                                                    ? "bg-blue-50 border-2 border-blue-200"
                                                    : "hover:bg-gray-50 border border-gray-100"
                                                }`}
                                        >
                                            <div className="flex items-center gap-4">
                                                {/* Tutor Avatar */}
                                                <div className="flex-shrink-0">
                                                    <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center">
                                                        <span className="text-blue-600 font-medium">
                                                            {tutorId.charAt(0).toUpperCase()}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="flex-1 min-w-0">
                                                    <div className="flex justify-between items-start">
                                                        <h3 className="font-semibold text-gray-900 truncate">
                                                            Tutor {tutorId}
                                                        </h3>
                                                        {unreadCount > 0 && (
                                                            <span className="px-2 py-1 text-xs font-medium text-white bg-blue-500 rounded-full">
                                                                {unreadCount}
                                                            </span>
                                                        )}
                                                    </div>
                                                    {lastMessage && (
                                                        <p className="text-sm text-gray-600 mt-1 line-clamp-2">
                                                            {lastMessage.content}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>

                        {/* Chat Area */}
                        <div className="flex-1 ring-1 bg-white rounded-2xl shadow-lg h-[calc(100vh-12rem)] overflow-hidden">
                            {selectedParticipant ? (
                                <Chat otherUserId={selectedParticipant} />
                            ) : (
                                <div className="flex flex-col items-center justify-center h-full text-gray-500">
                                    <svg className="w-24 h-24 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                    </svg>
                                    <h3 className="text-xl font-medium mb-2">Select a Conversation</h3>
                                    <p className="text-sm">Choose a tutor to start chatting</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmployerMessages;