import React, { useEffect, useState } from "react";
import InstitutionNavbar from "../InstitutionNavbar/InstitutionNavbar";
import styles from "./styles.module.css";
import Sidebar from "../Sidebar/Sidebar";
import SizedBox from "../../Widgets/SizedBox";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from "../../../config";
import { MapPin } from "lucide-react";

const ExpiredJobs = () => {
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    getJobs();
  }, []);

  function getJobs() {
    setLoading(true);
    fetch(`${host}/api/${v}/institution/expiredjob`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        return response.json();
      })
      .then((data) => setJobs(data.jobs))
      .catch((error) => console.error("Error during fetch:", error))
      .finally(() => setLoading(false));
  }

  const handleCloseSelectedJobs = (job) => {
    fetch(`${host}/api/${v}/institution/reopen_job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify({ jobId: job.jobId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        if (response.status === 200) {
          alert("Job Repoened Successfull !");
          getJobs();
        }
        return response.json();
      })

      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      });
  };

  return (
    <React.Fragment>
      <InstitutionNavbar title="Expired / Cancelled Jobs" />
      <div className={styles.flex}>
        <div>
          <Sidebar />
        </div>
        <div className={styles.card}>
          <div className={`${styles.flex} ${styles.spaceBetween}`}>
            <div className={styles.heading}>Closed Jobs</div>
            <div>Found: {jobs.length} Job</div>
          </div>
          <SizedBox height="26px" />
          {loading ? (
            <div className={styles.noJobs}>
              <LoadingSpinner />
            </div>
          ) : jobs.length ? (
            jobs.map((job, i) => (
              <div className="flex flex-col md:flex-row w-full gap-4 p-4 border rounded-lg shadow-sm bg-white">
                <div className="flex-shrink-0 font-medium text-lg text-gray-600">
                  {i + 1}
                </div>

                <div className="relative w-[240px]">
                  <img
                    src="https://www.teachaway.com/sites/default/files/styles/header_image_1024x575/public/job-board-main_0.png"
                    alt="Job"
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>

                <div className="flex flex-col flex-grow gap-2">
                  <h3 className="text-xl font-semibold text-gray-800">
                    {job.name}
                  </h3>
                  <div className="flex gap-8">
                    <div className="flex items-center gap-1 text-gray-600">
                      <MapPin className="w-4 h-4" />
                      <span>Pincode: {job.pincode}</span>
                    </div>
                    <div className="flex items-center gap-1 text-gray-600">
                      <MapPin className="w-4 h-4" />
                      <span>Location: MTR, U.P.</span>
                    </div>
                  </div>
                  <p className="text-gray-700 mt-2">{job.description}</p>
                </div>

                <button
                  onClick={() => handleCloseSelectedJobs(job)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Re-Open
                </button>
              </div>
            ))
          ) : (
            <div className={styles.noJobs}>No Jobs Expired yet!</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExpiredJobs;
