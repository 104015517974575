import React from "react";
import {
  SearchIcon,
  User2Icon,
  PenSquare,
  UserCheck,
  User2,
  Search,
} from "lucide-react";
import { Link } from "react-router-dom";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import Footer from "../../CommonWidget/Footer/Footer";

const HireTeachers = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <Navbar />

      <main className="max-w-7xl px-3">
        {/* Hero Section */}
        <section className="flex flex-col lg:flex-row gap-8 lg:gap-20 py-8 lg:my-12 px-4 lg:mx-0">
          <div className="w-full lg:w-2/3">
            <h1 className="text-3xl lg:text-4xl font-bold text-blue-500 mb-4">
              Find the Right Educators for Every Level..!
            </h1>
            <p className="text-gray-700 text-base lg:text-lg mb-6">
              Welcome to TUTOR PLATTER, your one-stop solution for recruiting
              skilled educators who can shape minds and inspire learning.
              Whether you are a school seeking experienced Montessori teachers
              to nurture young children or a college searching for distinguished
              professors to lead advanced academic programs, our platform
              connects you with the best talent in education
            </p>
            <Link
              to="/salesteam"
              className="inline-block px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors"
            >
              Sign Up Now
            </Link>
          </div>

          <div className="w-full lg:w-1/3 lg:pl-12">
            <h2 className="text-xl text-green-600 text-center font-semibold mb-6">
              Register As an Employer
            </h2>
            <div className="flex flex-col gap-4 p-6 rounded-lg shadow-md bg-white">
              <img
                src="/images/hire_teacher.webp"
                alt="Hire Teacher"
                className="w-full shadow-lg rounded-lg transition-all duration-300 hover:shadow-xl"
              />
              <Link
                to="/salesteam"
                className="w-full text-center py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors"
              >
                Register Now
              </Link>
            </div>
          </div>
        </section>

        {/* Features */}
        <section className="my-8 lg:my-12 px-4">
          <h2 className="text-2xl lg:text-3xl font-bold mb-6 lg:mb-8">
            Why Choose <span className="text-green-500">TUTOR PLATTER</span>?
          </h2>
          <p className="text-gray-700 mb-8 lg:mb-12">
            TutorPlatter is dedicated to bridging the gap between students and
            top-notch tutors worldwide. Whether you're seeking expert help or
            looking to share your knowledge, our platform offers a seamless
            experience for everyone.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8">
            <div className="p-4 lg:p-6 border rounded-lg hover:shadow-lg transition-shadow">
              <img
                src="https://i.ibb.co/fvJZ1Gg/Job.png"
                alt="tutor"
                className="w-16 h-16 mb-4 mx-auto"
              />
              <h3 className="text-lg text-center lg:text-xl font-semibold mb-4">
                Diverse Talent Pool
              </h3>
              <ul className="space-y-2">
                <li className="flex items-start gap-2">
                  <span className="text-green-500 mt-1">•</span>
                  <span>
                    Access thousands of qualified candidates specializing in
                    Montessori education
                  </span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-green-500 mt-1">•</span>
                  <span>
                    Explore profiles with detailed resumes and certifications
                  </span>
                </li>
              </ul>
            </div>
            <div className="p-4 lg:p-6 border rounded-lg hover:shadow-lg transition-shadow">
              <img
                src="https://i.ibb.co/WkvRW2W/Deal.png"
                alt="tutor"
                className="w-16 h-16 mb-4 mx-auto"
              />
              <h3 className="text-lg text-center lg:text-xl font-semibold mb-4">
                Tailored Recruitment Solutions
              </h3>
              <ul className="space-y-2">
                <li className="flex items-start gap-2">
                  <span className="text-green-500 mt-1">•</span>
                  <span>Post job openings with specific requirements</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-green-500 mt-1">•</span>
                  <span>
                    Advanced search filters for precise candidate matching
                  </span>
                </li>
              </ul>
            </div>
            <div className="p-4 lg:p-6 border rounded-lg hover:shadow-lg transition-shadow">
              <img
                src="/icons/work.png"
                alt="tutor"
                className="w-16 h-16 mb-4 mx-auto"
              />
              <h3 className="text-lg text-center lg:text-xl font-semibold mb-4">
                Simplified Hiring Process
              </h3>
              <ul className="space-y-2">
                <li className="flex items-start gap-2">
                  <span className="text-green-500 mt-1">•</span>
                  <span>Manage job postings and track applications easily</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-green-500 mt-1">•</span>
                  <span>Communicate directly with potential hires</span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* How it Works */}
        <section className="my-8 lg:my-12 px-4 py-8 lg:py-12 bg-gray-50">
          <h2 className="text-2xl lg:text-3xl font-bold text-center mb-8 lg:mb-12">
            How <span className="text-green-600">Tutor Platter</span> works
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 max-w-4xl mx-auto">
            {[
              {
                icon: User2,
                title: "Create account",
                description:
                  "Create your profile and start posting job openings.",
              },
              {
                icon: PenSquare,
                title: "Post Jobs",
                description: "Add detailed descriptions to attract top talent.",
              },
              {
                icon: Search,
                title: "Search and Shortlist",
                description:
                  "Use our search tools to find the perfect candidates.",
              },
              {
                icon: UserCheck,
                title: "Hire with Confidence",
                description:
                  "Make informed decisions using our comprehensive profiles.",
              },
            ].map((step, index) => (
              <div key={index} className="text-center">
                <step.icon size={40} className="mx-auto mb-4 text-blue-500" />
                <h3 className="font-semibold mb-2">{step.title}</h3>
                <p className="text-sm text-gray-600">{step.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* CTA Section */}
        <div
          className="max-w-3xl mx-auto flex flex-col sm:flex-row justify-between items-center bg-blue-600 gap-6 my-4 lg:my-8 p-6 lg:py-8 rounded-lg"        >
          <div className="z-10">
            <h3 className="text-xl lg:text-2xl text-white font-bold mb-2 lg:mb-4 text-center sm:text-left drop-shadow-lg text-shadow">
              Become an Employer
            </h3>
            <p className="mb-4 text-white text-shadow sm:mb-0 text-center sm:text-left">
              Find the perfect teaching talent for your institution.
            </p>
          </div>
          <Link
            to="/salesteam"
            className="relative z-10 w-full sm:w-auto px-6 py-3 bg-white text-blue-600 rounded-md text-center hover:bg-blue-50 transition-colors"
          >
            Register Now
          </Link>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default HireTeachers;
