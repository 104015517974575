import React from "react";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import Footer from "../../CommonWidget/Footer/Footer";
import { Link } from "react-router-dom";

const FeaturedJob = () => {
  const jobs = [
    {
      id: 1,
      icon: "https://a.slack-edge.com/80588/marketing/img/meta/favicon-32.png",
      title: "Lead Product Designer",
      description:
        "We are looking for a lead product designer. A good suitable professional person for this very important role. Anyone can apply for this...",
      tags: ["Fulltime", "Remote"],
      location: "Worldwide",
      postedDays: 5,
    },
    {
      id: 2,
      icon: "https://byjus.com/wp-content/uploads/2022/10/favicon-32x32.png",
      title: "Front-end Developer",
      description:
        "We are looking for a front end developer. A good suitable professional person for this very important role. Anyone can apply for this...",
      tags: ["Fulltime", "Remote"],
      location: "Sylhet, Bangladesh",
      postedDays: 4,
    },
  ];

  return (
    <main>
      <Navbar />
      <div className="relative h-80">
        {/* Background Image with Overlay */}
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url('/images/students.png')`,
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>

        {/* Content */}
        <div className="relative h-full flex flex-col items-center justify-center text-white px-4 md:px-8">
          <h1 className="text-4xl text-white md:text-5xl font-bold text-center mb-6">
            Your dream teaching jobs both
            <br />
            in India and abroad
          </h1>

          <p className="text-lg md:text-xl text-center text-gray-300 mb-8 max-w-2xl">
            Discover premium part-time and full-time teaching opportunities in
            India and abroad. Explore roles in schools, colleges, and home
            tuitions across the Middle East, Africa, Southeast Asia, and beyond.
          </p>

          <Link
            to="/register_tutor"
            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-8 rounded-md transition-colors duration-200"
          >
            Start your journey today and make an impact! 🚀
          </Link>
        </div>
      </div>

      <section className="py-16 px-4 md:px-8">
        <div className="max-w-6xl mx-auto">
          <div className="flex justify-between items-center mb-12">
            <h2 className="text-3xl font-bold">Featured Jobs</h2>
            <Link
              to="/register_tutor"
              className="text-blue-600 hover:text-blue-700 font-medium flex items-center"
            >
              View All
              <svg
                className="w-4 h-4 ml-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </Link>
          </div>

          <div className="space-y-6">
            {jobs.map((job) => (
              <div
                key={job.id}
                className="border rounded-lg p-6 flex items-start justify-between"
              >
                <div className="flex items-start space-x-4">
                  <img src={job.icon} alt="" className="w-10 h-10 rounded-lg" />
                  <div>
                    <h3 className="text-xl font-bold mb-2">{job.title}</h3>
                    <p className="text-gray-600 mb-4">{job.description}</p>
                    <div className="flex items-center space-x-6">
                      <div className="flex space-x-2">
                        {job.tags.map((tag) => (
                          <span
                            key={tag}
                            className="bg-yellow-50 text-gray-800 px-3 py-1 rounded-full text-sm"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                      <div className="flex items-center space-x-4 text-gray-500">
                        <span className="flex items-center">
                          <svg
                            className="w-4 h-4 mr-1"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                          {job.location}
                        </span>
                        <span className="flex items-center">
                          <svg
                            className="w-4 h-4 mr-1"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          {job.postedDays} days ago
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <Link
                  to="/register_tutor"
                  className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-md"
                >
                  Apply Now
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
};

export default FeaturedJob;
