import React, { useState, useEffect, useRef } from 'react';
import { jwtDecode } from "jwt-decode";
import { io } from 'socket.io-client';
import { ioPort } from '../../../config';

const Chat = ({ otherUserId }) => {
    const token = localStorage.getItem("auth");
    const insid = jwtDecode(token).institutionId;
    const tutorId = jwtDecode(token).tutorId;
    let userId = '';
    if (insid)
        userId = `e${insid}`;

    if (tutorId)
        userId = `t${tutorId}`;

    const [socket, setSocket] = useState(null);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [isOtherUserActive, setIsOtherUserActive] = useState(false);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () =>
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      };


    useEffect(() => {
        const newSocket = io(`http://localhost:${ioPort}`, {
            auth: { userId }
        });

        newSocket.on('connect', () => {
            // Mark chat as active
            newSocket.emit('user-active-in-chat', { otherUserId });
            // Load chat history
            newSocket.emit('get-chat-history', { otherUserId });
        });

        newSocket.on('chat-history', (history) => {
            setMessages(history);
            scrollToBottom()
        });

        newSocket.on('private-message', (data) => {
            setMessages(prev => [...prev, data]);
            scrollToBottom();
            // Mark received message as seen immediately if chat is open
            newSocket.emit('mark-messages-seen', { otherUserId });
        });

        newSocket.on('messages-seen', ({ by, seenAt }) => {
            if (by === otherUserId) {
                setMessages(prev => prev.map(msg =>
                    msg.sender === userId && !msg.seenAt ? { ...msg, seenAt } : msg
                ));
            }
        });

        newSocket.on('user-active', ({ userId: activeUserId }) => {
            if (activeUserId === otherUserId) {
                setIsOtherUserActive(true);
            }
        });

        setSocket(newSocket);
        return () => {
            newSocket.off('private-message');
            newSocket.off('messages-seen');
            newSocket.off('user-active');
            newSocket.close();
        };
    }, [userId, otherUserId]);

    // Mark messages as seen when chat is opened
    useEffect(() => {
        if (socket) {
            socket.emit('mark-messages-seen', { otherUserId });
        }
    }, [socket, otherUserId]);

    const sendMessage = (e) => {
        e.preventDefault();
        if (message.trim()) {
            socket.emit('private-message', {
                to: otherUserId,
                content: message
            });
            setMessages(prev => [...prev, {
                sender: userId,
                content: message,
                timestamp: new Date()
            }]);
            setMessage('');
        }
    };


    return (
        <div className="flex flex-col w-full h-full bg-gray-50">
            {/* Messages Container */}
            <div className="flex-1 overflow-y-auto px-4 py-6">
                <div className="mx-4 space-y-4">
                    {messages.map((msg, i) => {
                        const isCurrentUser = msg.sender === userId;
                        return (
                            <div
                                key={i}
                                className={`flex ${isCurrentUser ? 'justify-end' : 'justify-start'}`}
                            >
                                <div
                                    className={`max-w-[70%] group ${isCurrentUser ? 'items-end' : 'items-start'
                                        }`}
                                >
                                    <div
                                        className={`relative px-4 py-3 rounded-2xl shadow-sm
                        ${isCurrentUser
                                                ? 'bg-blue-500'
                                                : 'bg-white border border-gray-100'
                                            }
                      `}
                                    >
                                        <p className={`text-sm ${isCurrentUser? "text-white": "text-gray-800"} leading-relaxed whitespace-pre-wrap break-words`}>
                                            {msg.content}
                                        </p>
                                        <span
                                            className={`block text-xs mt-1
                          ${isCurrentUser
                                                    ? 'text-blue-100'
                                                    : 'text-gray-500'
                                                }
                        `}
                                        >
                                            {formatTime(msg.timestamp)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div ref={messagesEndRef} />
                </div>
            </div>

            {/* Message Input Form */}
            <div className="border-t bg-white px-4 py-4">
                <div className="mx-4">
                    <form onSubmit={sendMessage} className="flex gap-3">
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type your message..."
                            className="flex-1 px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                        />
                        <button
                            type="submit"
                            className="px-6 py-3 bg-blue-500 text-white font-medium rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                            disabled={!message.trim()}
                        >
                            <span className="flex items-center gap-2">
                                Send
                                <svg
                                    className="w-4 h-4"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                                    />
                                </svg>
                            </span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Chat;