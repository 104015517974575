import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, MessageCircleMore, X } from "lucide-react";
import { jwtDecode } from "jwt-decode";
import { mainColor } from "../../../config";

const InstitutionNavbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [insName, setInsName] = useState("");

  useEffect(() => {
    try {
      const token = localStorage.getItem("auth");
      const name = jwtDecode(token).name;
      if (name) setInsName(name);
    } catch {}
  }, []);

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const NavLink = ({ to, children }) => (
    <Link
      to={to}
      className="text-gray-700 hover:text-blue-600 px-4 py-2 rounded-md text-sm font-medium"
      onClick={() => setIsOpen(false)}
    >
      {children}
    </Link>
  );

  return (
    <nav className="bg-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <img src="/logo.png" alt="Logo" className="w-14 h-auto" />
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-4">
            <NavLink to="/institution/dashboard">Dashboard</NavLink>
            <NavLink to="/institution/jobposts">Active Jobs</NavLink>
            <NavLink to="/institution/expiredjobs">Closed Jobs</NavLink>
            <NavLink to="/institution/post_new_job">Post New Job</NavLink>
            <NavLink to="/institution/search_by_skills">Search Tutors</NavLink>
            <NavLink to="/institution/messages"><MessageCircleMore /></NavLink>
            

            <NavLink to="/institution/profile">
              {insName?.length > 15
                ? `${insName.substring(0, 15)}...`
                : insName}
            </NavLink>

            <button
              onClick={logout}
              className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md text-sm font-medium"
            >
              Logout
            </button>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-blue-600"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <NavLink to="/institution/dashboard">Dashboard</NavLink>
            <NavLink to="/institution/jobposts">Active Jobs</NavLink>
            <NavLink to="/institution/expiredjobs">Closed Jobs</NavLink>
            <NavLink to="/institution/post_new_job">Post New Job</NavLink>

            <Link
              to="/institution/profile"
              className="block text-blue-600 font-medium px-4 py-2"
            >
              {insName?.length > 15
                ? `${insName.substring(0, 15)}...`
                : insName}
            </Link>

            <button
              onClick={logout}
              className="w-full text-left bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md text-sm font-medium"
            >
              Logout
            </button>
          </div>
        </div>
      )}

      <div className={`h-8 bg-[${mainColor}] w-full`} />
    </nav>
  );
};

export default InstitutionNavbar;
