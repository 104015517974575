import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import { host, v } from "../../../config";
import Navbar from "../../CommonWidget/Navbar/Navbar";

const TutorLogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [userType, setUserType] = useState("tutor");

  const handleLogin = (e) => {
    e.preventDefault();

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Please Enter a Valid Email address");
      return;
    }
    if (password.trim().length === 0) {
      alert("Please Enter a Password");
      return;
    }

    setLoading(true);

    if (userType === "tutor") {
      fetch(`${host}/api/${v}/visitor/tutorlogin`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      })
        .then((response) => {
          if (response.status === 400) {
            response.text().then((text) => setErrorText(text));
            throw new Error("Bad Request");
          }
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data.msg === true) {
            navigate("/tutor/dashboard/see_jobs");
            if (data.token) {
              localStorage.setItem("auth", data.token);
              const expires = new Date(Date.now() + 7 * 864e5).toUTCString();
              document.cookie = `token=${encodeURIComponent(
                data.token
              )}; expires=${expires}; path=/`;
            }
          }
        })
        .catch((error) => {
          setErrorText("Invalid Credentials!");
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (userType === "institution" || userType === "student") {
      fetch(`${host}/api/${v}/visitor/institutionlogin`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      })
        .then((response) => {
          if (response.status === 400) {
            setErrorText("Invalid Credentials!");
          }
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data.msg === true) {
            navigate("/institution/dashboard");
            if (data.token) {
              localStorage.setItem("auth", data.token);
            }
          }
        })
        .catch((error) => {
          setErrorText("Invalid Credentials!");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };


  return (
    <>
      <Navbar />
      <div className="min-h-[88vh] flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 p-6 lg:p-12 flex flex-col items-center justify-between">
            <div className="flex gap-2 items-center">
              <label className="flex items-center p-3 cursor-pointer hover:bg-gray-50 transition-colors">
                <input
                  type="radio"
                  name="userType"
                  value="tutor"
                  checked={userType === "tutor"}
                  onChange={() =>  setUserType("tutor")}
                  className="w-4 h-4 text-blue-500"
                />
                <span className="ml-2">Tutor</span>
              </label>

              <label className="flex items-center p-3 cursor-pointer hover:bg-gray-50 transition-colors">
                <input
                  type="radio"
                  name="userType"
                  value="institution"
                  checked={userType === "institution"}
                  onChange={() =>  setUserType("institution")}
                  className="w-4 h-4 text-blue-500"
                />
                <span className="ml-2">Institution</span>
              </label>

              <label className="flex items-center p-3 cursor-pointer hover:bg-gray-50 transition-colors">
                <input
                  type="radio"
                  name="userType"
                  value="student"
                  checked={userType === "student"}
                  onChange={() =>  setUserType("student")}
                  className="w-4 h-4 text-blue-500"
                />
                <span className="ml-2">Student</span>
              </label>
            </div>

          <div className="w-full max-w-md space-y-6">
            {userType === "tutor" && (
              <div className="text-center lg:text-left">
                <h1 className="text-2xl lg:text-3xl font-bold mb-2">
                  Welcome Back Tutor!
                </h1>
              </div>
            )}

            {userType === "institution" && (
              <div className="text-center lg:text-left">
                <h1 className="text-2xl lg:text-3xl font-bold mb-2">
                  Welcome Back Employer!
                </h1>
              </div>
            )}

            {userType === "student" && (
              <div className="text-center lg:text-left">
                <h1 className="text-2xl lg:text-3xl font-bold mb-2">
                  Welcome Back Student!
                </h1>
              </div>
            )}

            {errorText && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                {errorText}
              </div>
            )}

            <form onSubmit={handleLogin} className="space-y-4">
              <div>
                <input
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <button
                  type="button"
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:bg-blue-300"
              >
                {loading ? "Loging in..." : "Log in"}
              </button>
            </form>

            <p className="text-center text-sm text-gray-600">
              Don't have an account?{" "}
              {userType === "tutor" && (
                <Link
                  to="/register_tutor"
                  className="text-blue-500 hover:underline"
                >
                  Sign up now
                </Link>
              )}
              {userType === "institution" && (
                <Link to="/salesteam" className="text-blue-500 hover:underline">
                  Sign up now
                </Link>
              )}
              {userType === "student" && (
                <Link
                  to="/register_student"
                  className="text-blue-500 hover:underline"
                >
                  Sign up now
                </Link>
              )}
            </p>
          </div>

          <div />
        </div>

        {userType === "student" && (
          <div
            className="hidden lg:block w-1/2 bg-cover bg-center no-repeat"
            style={{
              backgroundImage: 'url("/images/studentlogin.jpg")',
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
        )}
        {userType === "institution" && (
          <div
            className="hidden lg:block w-1/2 bg-cover bg-center no-repeat"
            style={{
              backgroundImage: 'url("/images/institutionlogin.avif")',
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
        )}
        {userType === "tutor" && (
          <div
            className="hidden lg:block w-1/2 bg-cover bg-center"
            style={{
              backgroundImage: 'url("/images/tutorlogin.webp")',
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
        )}
      </div>
    </>
  );
};

export default TutorLogin;
