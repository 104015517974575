import React from "react";
import { Mail, Facebook, Twitter, Instagram, Linkedin } from "lucide-react";

const Footer = () => {
  return (
    <footer className="relative w-screen left-[calc(-50vw+50%)] bg-gray-900 text-gray-300 overflow-x-hidden">
      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Company Info */}
          <div>
            <img
              alt="Tutor Platter"
              className="h-20 mb-4"
              src="https://usercentereddesign.co.in/tutor/images/logo.png"
            />
            <h3 className="text-white text-lg font-bold mb-4">Tutor Platter</h3>
            <p className="mb-4">
              Creating innovative solutions for tomorrow's challenges.
            </p>
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <Mail size={18} />
                <span>contact@tutorplatter.com</span>
              </div>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-white text-lg font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="/" className="hover:text-white transition-colors">
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/about_us"
                  className="hover:text-white transition-colors"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/hireteachers"
                  className="hover:text-white transition-colors"
                >
                  Hire Teachers
                </a>
              </li>
              <li>
                <a
                  href="/seejobs"
                  className="hover:text-white transition-colors"
                >
                  Featured Jobs
                </a>
              </li>
            </ul>
          </div>

          {/* Services */}
          <div>
            <h3 className="text-white text-lg font-bold mb-4">Services</h3>
            <ul className="space-y-2">
              <li>
                <a href="#" className="hover:text-white transition-colors">
                  One to One Consultancy
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white transition-colors">
                  Train Teahcers
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white transition-colors">
                  Provide Teachers
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white transition-colors">
                  Consulting
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="mt-12 pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="text-sm">
              © {new Date().getFullYear()} Company Name. All rights reserved.
            </div>
            {/* Social Links */}
            <div className="flex space-x-4">
              <a href="#" className="hover:text-white transition-colors">
                <Facebook size={20} />
              </a>
              <a href="#" className="hover:text-white transition-colors">
                <Twitter size={20} />
              </a>
              <a href="#" className="hover:text-white transition-colors">
                <Instagram size={20} />
              </a>
              <a href="#" className="hover:text-white transition-colors">
                <Linkedin size={20} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
