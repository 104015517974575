import React, { useState } from "react";
import { Menu, X } from "lucide-react";
import { Link } from "react-router-dom";
import { mainColor } from "../../../config";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative w-screen left-[calc(-50vw+50%)] w-full">

<style>{`@font-face {
        font-family: 'GetVoIP Grotesque';
        src: url('/fonts/getVoip.ttf') format('truetype');}`}</style>


      {(window.location.href === "http://localhost:3000/" || window.location.href === "https://tutorplatter.com/") ? (
        <>
          <div className="h-8 bg-[#7ED958] w-full mb-1" />
          <nav className="relative bg-white mb-2">
            <div className="mx-auto px-12">
              <div className="flex items-center justify-between">
                {/* Logo */}
          
                <div className="flex flex-col justify-center"  style={{ fontFamily: 'GetVoIP Grotesque' }}>
                  <div className="text-[#7ED958] text-base sm:text-lg lg:text-xl font-bold tracking-widest">
                    TUTOR PLATTER
                  </div>
                  <div className="w-32 h-0.5 bg-[#7ED958] mx-auto"></div>
                  <div className="text-[#7ED958] text-center text-sm tracking-wider">
                    TEACH THE WORLD
                  </div>
                </div>

                {/* Desktop Menu */}
                <div className="hidden md:flex items-center space-x-8">
                  <Link to="/" className="text-gray-800 hover:text-gray-600">
                    HOME
                  </Link>
                  <Link
                    to="/seejobs"
                    className="text-gray-800 hover:text-gray-600"
                  >
                    FEATURED JOBS
                  </Link>
                  <Link
                    to="/students"
                    className="text-gray-800 hover:text-gray-600"
                  >
                    STUDENTS
                  </Link>
                  <Link
                    to="/hireteachers"
                    className="text-gray-800 hover:text-gray-600"
                  >
                    HIRE TEACHERS
                  </Link>
                  <Link
                    to="/about_us"
                    className="text-gray-800 hover:text-gray-600"
                  >
                    ABOUT US
                  </Link>
                  <Link
                    to="/tutorlogin"
                    className="text-gray-800 hover:text-gray-600"
                  >
                    LOGIN
                  </Link>
                  <Link
                    to="/register1"
                    className={`text-white bg-[${mainColor}] px-8 py-2 rounded-md hover:text-gray-800 hover:bg-white`}
                  >
                    REGISTER
                  </Link>
                </div>

                {/* Mobile Menu Button */}
                <button
                  className="md:hidden p-2 rounded-md hover:bg-gray-100"
                  onClick={toggleMenu}
                >
                  {isOpen ? (
                    <X className="h-6 w-6 text-gray-600" />
                  ) : (
                    <Menu className="h-6 w-6 text-gray-600" />
                  )}
                </button>
              </div>

              {/* Mobile Menu */}
              {isOpen && (
                <div className="md:hidden absolute top-16 left-0 right-0 bg-white border-b border-gray-200 z-10">
                  <div className="flex flex-col space-y-4 px-4 py-6">
                    <a
                      href="/"
                      className="text-gray-800 hover:text-gray-600 text-lg"
                      onClick={toggleMenu}
                    >
                      HOME
                    </a>
                    <a
                      href="/seejobs"
                      className="text-gray-800 hover:text-gray-600 text-lg"
                      onClick={toggleMenu}
                    >
                      FEATURED JOBS
                    </a>
                    <a
                      href="/hireteachers"
                      className="text-gray-800 hover:text-gray-600 text-lg"
                      onClick={toggleMenu}
                    >
                      HIRE TEACHERS
                    </a>
                    <Link
                      to="/about_us"
                      className="text-gray-800 hover:text-gray-600 text-lg"
                      onClick={toggleMenu}
                    >
                      ABOUT US
                    </Link>
                    <a
                      href="/tutorlogin"
                      className="text-gray-800 hover:text-gray-600 text-lg"
                      onClick={toggleMenu}
                    >
                      LOGIN
                    </a>
                    <Link
                      to="/register1"
                      className="text-gray-800 hover:text-gray-600"
                    >
                      REGISTER
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </nav>
        </>
      ) : (
        <>
          <nav className="relative bg-white mb-2">
            <div className="mx-auto px-12">
              <div className="flex items-center justify-between">
                {/* Logo */}
                <div className="flex flex-col justify-center"  style={{ fontFamily: 'GetVoIP Grotesque' }}>
                  <div className="text-[#7ED958] text-base sm:text-lg lg:text-xl font-bold tracking-widest">
                    TUTOR PLATTER
                  </div>
                  <div className="w-32 h-0.5 bg-[#7ED958] mx-auto"></div>
                  <div className="text-[#7ED958] text-center text-sm tracking-wider">
                    TEACH THE WORLD
                  </div>
                </div>
                
                {/* Desktop Menu */}
                <div className="hidden md:flex items-center space-x-8">
                  <Link to="/" className="text-gray-800 hover:text-gray-600">
                    HOME
                  </Link>
                  <Link
                    to="/seejobs"
                    className="text-gray-800 hover:text-gray-600"
                  >
                    FEATURED JOBS
                  </Link>
                  <Link
                    to="/students"
                    className="text-gray-800 hover:text-gray-600"
                  >
                    STUDENTS
                  </Link>
                  <Link
                    to="/hireteachers"
                    className="text-gray-800 hover:text-gray-600"
                  >
                    HIRE TEACHERS
                  </Link>
                  <Link
                    to="/about_us"
                    className="text-gray-800 hover:text-gray-600"
                  >
                    ABOUT US
                  </Link>
                  <Link
                    to="/tutorlogin"
                    className="text-gray-800 hover:text-gray-600"
                  >
                    LOGIN
                  </Link>
                  <Link
                    to="/register1"
                    className={`text-white bg-[${mainColor}] px-8 py-2 rounded-md hover:text-gray-800 hover:bg-white`}
                  >
                    REGISTER
                  </Link>
                </div>

                {/* Mobile Menu Button */}
                <button
                  className="md:hidden p-2 rounded-md hover:bg-gray-100"
                  onClick={toggleMenu}
                >
                  {isOpen ? (
                    <X className="h-6 w-6 text-gray-600" />
                  ) : (
                    <Menu className="h-6 w-6 text-gray-600" />
                  )}
                </button>
              </div>

              {/* Mobile Menu */}
              {isOpen && (
                 <div className="md:hidden absolute top-16 left-0 right-0 bg-white border-b border-gray-200 z-10">
                  <div className="flex flex-col space-y-4 px-4 py-6">
                    <a
                      href="/"
                      className="text-gray-800 hover:text-gray-600 text-lg"
                      onClick={toggleMenu}
                    >
                      HOME
                    </a>
                    <a
                      href="/seejobs"
                      className="text-gray-800 hover:text-gray-600 text-lg"
                      onClick={toggleMenu}
                    >
                      FEATURED JOBS
                    </a>
                    <a
                      href="/hireteachers"
                      className="text-gray-800 hover:text-gray-600 text-lg"
                      onClick={toggleMenu}
                    >
                      HIRE TEACHERS
                    </a>
                    <Link
                      to="/about_us"
                      className="text-gray-800 hover:text-gray-600 text-lg"
                      onClick={toggleMenu}
                    >
                      ABOUT US
                    </Link>
                    <a
                      href="/tutorlogin"
                      className="text-gray-800 hover:text-gray-600 text-lg"
                      onClick={toggleMenu}
                    >
                      LOGIN
                    </a>
                    <Link
                      to="/register1"
                      className="text-gray-800 hover:text-gray-600"
                    >
                      REGISTER
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </nav>
          {/* Green Bar */}
          <div className="h-8 bg-[#7ED958] w-full" />
        </>
      )}
    </div>
  );
}
