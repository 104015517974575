import React, { useState } from "react";
import Navbar from "../../../CommonWidget/Navbar/Navbar";
import {
  Box,
  Button,
  Container,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SizedBox from "../../../Widgets/SizedBox";
import LoadingSpinner from "../../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from '../../../../config'
import { useNavigate } from "react-router-dom";

const StudentRegistration = () => {
  const navigate = useNavigate();
  const [loading, Loading] = useState(false);
  const [name, Name] = useState("");
  const [contactPerson, ContactPerson] = useState("");
  const [email, Email] = useState("");
  const [password, Password] = useState("");
  const [confPassword, ConfPassword] = useState("");
  const [phone, Phone] = useState("");
  const [pincode, Pincode] = useState("");
  const [add, Add] = useState("");
  const [typ, Typ] = useState("");
  const [scTyp1, ScTyp1] = useState("");
  const [scTyp2, ScTyp2] = useState("");

  const [showPass, ShowPass] = useState(false);
  const [showConfPass, ShowConfPass] = useState(false);

  const [showModal, ShowModal] = useState(false);

  function handleSignup() {
    if (
      !(
        name.trim().length &&
        email.trim().length &&
        password.trim().length &&
        confPassword.trim().length &&
        pincode.trim().length &&
        add.trim().length
      )
    ) {
      alert("Please Fill All details");
      return;
    }

    
    if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email)){
      alert("Please Enter a Valid Email address");
      return;
    }

    if (password.trim().length < 6) {
      alert("Passwords must be at least 6 characters");
      return;
    }
    if (!(/[a-z]/.test(password.trim()) && /[A-Z]/.test(password.trim()))) {
      alert("Password must have a Small Case & Upper Case Alphabet");
      return;
    }

    if (password != confPassword) {
      alert("Passwords do not Match");
      return;
    }

    Loading(true);

    fetch(`${host}/api/${v}/visitor/institutionregister`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: name,
        contactPerson: name,
        email: email,
        pass: password,
        phone: phone || '0',
        scTyp0: typ,
        scTyp1: "Private",
        scTyp2: "Primary School",
        annualFee: 1000,
        pincode: pincode,
        add: add,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if(response.status === 403){
            alert('Email Already Exist!');
          }
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.msg === "success") {
          ShowModal(true);
        }
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        Loading(false);
      });
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <React.Fragment>
      <Navbar />
      <Modal
        open={showModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 200 }}>
          <h2 id="child-modal-title">Registration Successfull</h2>
          <p id="child-modal-description">
            Our Sales Team will comunicate with you Shortly!
          </p>
          <Button
            onClick={() => {
              ShowModal(false);
              navigate("/");
            }}
          >
            Proceed
          </Button>
        </Box>
      </Modal>
      <div className="body">
        <Container
          className={`XCon`}
          component="main"
          maxWidth="xs"
        >
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Student Name"
                name="name"
                autoComplete="name"
                value={name}
                onChange={(v) => Name(v.target.value)}
                autoFocus
              />
             
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={email}
                onChange={(v) => Email(v.target.value)}
                autoComplete="email"
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                type={showPass ? "text" : "password"}
                name="password"
                value={password}
                onChange={(v) => Password(v.target.value)}
                autoComplete="true"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => ShowPass(!showPass)}
                        edge="end"
                      >
                        {showConfPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Confirm Password"
                type={showConfPass ? "text" : "password"}
                name="confirm password"
                value={confPassword}
                onChange={(e) => ConfPassword(e.target.value)}
                autoComplete="true"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => ShowConfPass(!showConfPass)}
                        edge="end"
                      >
                        {showConfPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <FormControl>
                <div className="left">Type</div>
                <Select
                  labelId="demo-simple-select-label"
                  id="type"
                  value={typ}
                  onChange={(v) => Typ(v.target.value)}
                >
                  <MenuItem value={"Individual"}>Individual</MenuItem>
                  <MenuItem value={"Group"}>Group</MenuItem>
                </Select>
              </FormControl>

          
              <TextField
                margin="normal"
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete="phone"
                value={phone}
                onChange={(v) => Phone(v.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="Ppincode"
                label="Pincode"
                name="Pincode"
                autoComplete="pincode"
                value={pincode}
                onChange={(v) => Pincode(v.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                autoComplete="Address"
                value={add}
                onChange={(v) => Add(v.target.value)}
              />

              <br />

              <SizedBox height="18px" />
              {loading ? (
                <LoadingSpinner />
              ) : (
                <Button
                  style={{ width: "400px" }}
                  onClick={() => handleSignup()}
                  variant="contained"
                >
                  Submit
                </Button>
              )}
              <SizedBox height="40px" />
            </FormControl>
          </Box>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StudentRegistration;
