import React from "react";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import styles from "./styles.module.css";

const TutorForgetPass = () => {

  return (
    <React.Fragment>
      <Navbar />

      <div className={styles.body}>
        To reset your Password, please Email us on <a className="mx-2 underline text-blue-600" href="mailto:'support@tutorplatter.com'">support@tutorplatter.com</a> from your registered Email.
      </div>
    </React.Fragment>
  );
};

export default TutorForgetPass;
