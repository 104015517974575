import React, { useState } from 'react';
import { MessageCircle, Send } from 'lucide-react';
import InstitutionNavbar from '../InstitutionNavbar/InstitutionNavbar';

const EmployerConversations = () => {
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [messages, setMessages] = useState({});
    const [inputMessage, setInputMessage] = useState('');

    // Sample tutor data
    const tutors = [
        { id: 1, name: 'John Smith' },
        { id: 2, name: 'Sarah Johnson' },
        { id: 3, name: 'Mike Brown' },
        { id: 4, name: 'Emma Wilson' },
    ];

    const handleSendMessage = () => {
        if (!inputMessage.trim() || !selectedTutor) return;

        const newMessages = {
            ...messages,
            [selectedTutor.id]: [
                ...(messages[selectedTutor.id] || []),
                { text: inputMessage, sender: 'employer', timestamp: new Date() }
            ]
        };
        setMessages(newMessages);
        setInputMessage('');
    };

    return (
        <div className="flex flex-col">
            <InstitutionNavbar />
            <div className="flex h-full bg-gray-100">
                {/* Tutors List */}
                <div className="w-64 bg-white border-r border-gray-200">
                    <div className="p-4 border-b border-gray-200">
                        <h2 className="text-lg font-semibold">Tutors</h2>
                    </div>
                    <div className="overflow-y-auto">
                        {tutors.map(tutor => (
                            <div
                                key={tutor.id}
                                className={`p-4 cursor-pointer hover:bg-gray-50 ${selectedTutor?.id === tutor.id ? 'bg-blue-50' : ''
                                    }`}
                                onClick={() => setSelectedTutor(tutor)}
                            >
                                <div className="flex items-center space-x-3">
                                    <div className="flex-shrink-0">
                                        <MessageCircle className="w-6 h-6 text-gray-400" />
                                    </div>
                                    <div>
                                        <p className="font-medium">{tutor.name}</p>
                                        <p className="text-sm text-gray-500">{tutor.subject}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Chat Area */}
                <div className="w-full h-full flex flex-col">
                    {selectedTutor ? (
                        <>
                            {/* Chat Header */}
                            <div className="p-4 border-b border-gray-200 bg-white">
                                <h3 className="text-lg font-semibold">{selectedTutor.name}</h3>
                                <p className="text-sm text-gray-500">{selectedTutor.subject}</p>
                            </div>

                            {/* Messages */}
                            <div className="h-full overflow-y-auto p-4 space-y-4">
                                {messages[selectedTutor.id]?.map((message, index) => (
                                    <div
                                        key={index}
                                        className={`flex ${message.sender === 'employer' ? 'justify-end' : 'justify-start'
                                            }`}
                                    >
                                        <div
                                            className={`max-w-xs md:max-w-md rounded-lg p-3 ${message.sender === 'employer'
                                                ? 'bg-blue-500 text-white'
                                                : 'bg-gray-200'
                                                }`}
                                        >
                                            {message.text}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Message Input */}
                            <div className="p-4 border-t border-gray-200 bg-white">
                                <div className="flex space-x-4">
                                    <input
                                        type="text"
                                        value={inputMessage}
                                        onChange={(e) => setInputMessage(e.target.value)}
                                        placeholder="Type your message..."
                                        className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                                    />
                                    <button
                                        onClick={handleSendMessage}
                                        title="Send Message"
                                        className="p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                                    >
                                        <Send className="w-5 h-5" />
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="flex-1 flex items-center justify-center text-gray-500">
                            Select a tutor to start chatting
                        </div>
                    )}
                </div>
            </div>
        </div>

    );
};

export default EmployerConversations;