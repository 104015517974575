import React, { useEffect, useState } from "react";
import InstitutionNavbar from "../InstitutionNavbar/InstitutionNavbar";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import Sidebar from "../Sidebar/Sidebar";
import Chat from "../New1/Chat";
import { host, v } from "../../../config";
import { Link } from "react-router-dom";
import { Mail, MapPin, X } from "lucide-react";

const JobDetails = () => {
  const [loading, Loading] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [applicatns, setApplicants] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      Loading(true);
      const path = window.location.pathname;
      const parts = path.split("/");
      const postIdFromUrl = parts[parts.length - 1];
      fetch(`${host}/api/${v}/institution/jobposts/${postIdFromUrl}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setApplicants(data.jobs);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        })
        .finally(() => {
          Loading(false);
        });
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <InstitutionNavbar title="Jobs Detail" />
      <div className="flex">
        <div>
          <Sidebar />
        </div>
        <div className="w-full h-full bg-gray-100">
          <div className="m-5 p-5 w-[calc(100%-40px)] h-[calc(100vh-160px)] bg-white border border-gray-300 rounded-xl">
            <div className="flex justify-between">
              <div className="text-2xl">{`Tutors Applied on your Job`}</div>
              <div>{applicatns.length} Tutor(s) Applied</div>
            </div>

            <button onClick={()=>console.log(selectedApplicant)}>print</button>

            {loading ? (
              <div className="flex items-center justify-center h-[70vh] m-auto mt-10 text-xl">
                <LoadingSpinner />
              </div>
            ) : applicatns.length ? (
              applicatns.map((value, i) => (
                <div
                  key={i}
                  className="flex items-center gap-4 p-4 border rounded-lg shadow-sm hover:shadow-md transition-shadow"
                >
                  {selectedApplicant === value && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                      <div className="bg-white rounded-lg w-full max-w-lg">
                        {/* Dialog Header */}
                        <div className="flex items-center justify-between p-4 border-b">
                          <h3 className="text-lg font-semibold">
                            Chat with {selectedApplicant.tutorName}
                          </h3>
                          <button
                            onClick={()=>setSelectedApplicant(null)}
                            className="text-gray-500 hover:text-gray-700"
                          >
                            <X size={20} />
                          </button>
                        </div>

                        {/* Chat Messages Area */}
                        <div className="h-96 p-4 overflow-y-auto">
                          {/* You can map through chat messages here */}
                          <div className="text-gray-500 text-center">
                            Start your conversation with{" "}
                            {selectedApplicant.tutorName}
                          </div>
                        </div>

                        <Chat otherUserId={`t${selectedApplicant.tutorId}`} />
                      </div>
                    </div>
                  )}

                  <div className="flex items-center">
                    <span>{i + 1}</span>
                  </div>

                  <img
                    src={`${host}/tutor_profile/${value.tutorId}.png`}
                    className="w-24 h-24 object-cover rounded-md"
                    alt={`${value.tutorName}'s profile`}
                  />

                  <div className="flex-1">
                    <h3 className="text-lg font-semibold mb-2">
                      {value.tutorName}
                    </h3>
                    <div className="flex items-center gap-1 text-gray-600">
                      <MapPin size={16} />
                      <span>Pincode: {value.pincode}</span>
                    </div>
                    <div className="flex items-center gap-1 text-gray-600">
                      <Mail size={16} />
                      <span>Email: {value.email}</span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <button
                      onClick={() => {
                        setSelectedApplicant(value);
                        debugger
                      }}
                      className="text-white bg-green-500 px-6 py-2 rounded-lg"
                    >
                      Chat
                    </button>
                    <Link
                      to={`/tutor_profile/${value.emailVerifyUrl}?v=true`}
                      className="text-white bg-green-500 px-6 py-2 rounded-lg"
                    >
                      View Resume
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center h-[70vh] m-auto mt-10 text-xl">
                No Tutor Applied yet!
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobDetails;
