import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { host, v } from '../../../config';

const VerifyTutor = () => {
    const navigate = useNavigate();

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleVerification = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${host}/api/${v}/visitor/verify_email`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    verificationString: window.location.pathname.split('/')[2]
                })
            });
            if (response.ok) {
                setMessage('Email verified successfully!');
                navigate("/tutorlogin");
            } else {
                const error = await response.text();
                setMessage(error || 'Verification failed');
            }
        } catch (err) {
            setMessage('Error occurred during verification');
        }
        setLoading(false);
    };

    useEffect(() => {handleVerification()}, [])

    return (
        <div className="flex flex-col items-center gap-4 p-6">

            {loading && 'Verifying...'}
            {message && (
                <div className={`p-4 rounded ${message.includes('success') ? 'bg-green-100' : 'bg-red-100'
                    }`}>
                    {message}
                </div>
            )}
        </div>
    );
};

export default VerifyTutor;