import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Menu, X, Bell, MessageCircleMore } from "lucide-react";
import { mainColor } from "../../../config";

const TutorNavbar = () => {
  const navigate = useNavigate();
  const [tutName, setTutName] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [notifications, setNotifications] = useState(0);

  function getName() {
    try {
      const token = localStorage.getItem("auth");
      const name = jwtDecode(token).name;
      if (name) setTutName(name);
    } catch {}
  }

  useEffect(() => getName(), []);

  async function Logout() {
    localStorage.clear();
    navigate("/");
  }

  return (
    <div className="w-full">
      <nav className="bg-white px-[4vw] flex justify-between shadow-md">
        <div className="flex-shrink-0 flex items-center">
          <img
            alt="Tutor Platter"
            className="h-20 mb-4"
            src="https://usercentereddesign.co.in/tutor/images/logo.png"
          />
        </div>

        <div className="hidden md:flex items-center space-x-4">
          <Link to="/tutor/dashboard/see_jobs">
            <button className="px-4 py-2 rounded-md font-medium text-gray-700 hover:bg-gray-100">
              FIND JOBS
            </button>
          </Link>
          <Link to="/tutor/dashboard/applied_jobs">
            <button className="px-4 py-2 rounded-md font-medium text-gray-700 hover:bg-gray-100">
              APPLIED JOBS
            </button>
          </Link>
          <Link to="/tutor/messages">
            <button className="px-4 py-2 rounded-md font-medium text-gray-700 hover:bg-gray-100">
              <MessageCircleMore />
            </button>
          </Link>
          {/* <div className="relative">
            <button className="p-2 rounded-full hover:bg-gray-100">
              <Bell className="h-6 w-6 text-gray-700" />
              {notifications > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                  {notifications}
                </span>
              )}
            </button>
          </div> */}
          <Link
            to="/tutor/profile"
            className="px-4 py-2 text-gray-700 hover:text-gray-900"
          >
            {tutName}
          </Link>
          <button
            onClick={Logout}
            className="px-4 py-2 rounded-md font-medium bg-red-600 text-white hover:bg-red-700"
          >
            LOGOUT
          </button>
        </div>

        <div className="md:hidden flex items-center space-x-2">
          <div className="relative">
            <button className="p-2 rounded-full hover:bg-gray-100">
              <Bell className="h-6 w-6 text-gray-700" />
              {notifications > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                  {notifications}
                </span>
              )}
            </button>
          </div>
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="p-2 rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-100 focus:outline-none"
          >
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </div>

        {isMenuOpen && (
          <div className="md:hidden absolute top-16 left-0 w-full bg-white shadow-lg">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link
                to="/tutor/dashboard/see_jobs"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >
                FIND JOBS
              </Link>
              <Link
                to="/tutor/dashboard/applied_jobs"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >
                APPLIED JOBS
              </Link>
              <Link
                to="/tutor/profile"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >
                {tutName}
              </Link>
              <button
                onClick={Logout}
                className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-red-600 hover:text-red-900 hover:bg-red-50"
              >
                LOGOUT
              </button>
            </div>
          </div>
        )}
      </nav>

      <div className={`h-8 bg-[${mainColor}] w-full`} />
    </div>
  );
};

export default TutorNavbar;
