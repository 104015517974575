import React, { useState } from "react";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import Footer from "../../CommonWidget/Footer/Footer";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const AboutPage = () => {
  const [activeSection, setActiveSection] = useState("teachers");

  const sections = {
    teachers: {
      title: "For Teachers",
      content: [
        "Access to a broad range of teaching positions, from traditional schools to international institutions",
        "Tools to create a professional profile, upload resumes, and apply for jobs directly through our portal",
        "A platform for teachers to explore teaching roles in various subject areas, grade levels, and educational settings",
      ],
    },
    tutors: {
      title: "For Home Tutors & Coaches",
      content: [
        "A specialized platform for finding tutoring and coaching opportunities, whether you're teaching academic subjects, test prep, language learning, or specialized skills",
        "Opportunities to offer personalized lessons for students, either in-person or virtually, at flexible hours",
        "Build your own profile to highlight your skills, certifications, and expertise, and easily connect with families and students seeking guidance",
      ],
    },
    students: {
      title: "For Students & Parents",
      content: [
        "A comprehensive database of tutors offering tailored lessons in various subjects, including Math, Science, English, and even niche areas like art or music",
        "Search for qualified home tutors and coaches based on your location, subject, and tutoring style (one-on-one, group lessons, online)",
        "Direct communication with educators to ensure the best fit for academic and personal goals",
      ],
    },
  };

  return (
    <div className="max-w-7xl mx-auto px-4">
      <Navbar />
      <section className="mx-auto px-4 py-12 flex flex-col md:flex-row items-center justify-between gap-8">
        <div className="md:w-1/2 flex flex-col gap-6">
          <div className="space-y-2">
            <h2 className="text-gray-600 font-bold text-4xl">About</h2>
            <h1 className="text-5xl font-bold text-[#7BC144]">TUTOR PLATTER</h1>
          </div>

          <p className="text-gray-600 text-lg leading-relaxed">
            Welcome To TUTOR PLATTER, The Unique Online Platform Connecting
            Educators, Home Tutors, And Students With Top Schools, Institutions,
            And Families Worldwide.
          </p>

          <Link to='/register1' className="w-fit bg-blue-600 text-white px-8 py-3 rounded-md hover:bg-blue-700 transition-colors">
            REGISTER NOW
          </Link>
        </div>

        <div className="md:w-1/2">
          <img
            src="https://images.unsplash.com/photo-1523151401668-fff9e38a48e4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDd8fHxlbnwwfHx8fHw%3D"
            alt="Students studying together"
            className="w-full rounded-lg shadow-lg"
          />
        </div>
      </section>

      <section className="container py-16">
        <h1 className="text-4xl font-bold text-center mb-16">
          Our <span className="text-green-600">Vision & Mission</span>
        </h1>

        <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {/* Mission Card */}
          <div className="bg-white rounded-2xl shadow-lg p-8 flex flex-col items-center text-center">
            {/* Rocket Icon */}
            <div className="mb-6">
              <img src="/icons/mission.png" />
            </div>

            <h2 className="text-4xl font-bold mb-6">Our Mission</h2>

            <p className="text-gray-600 leading-relaxed mb-4">
              Our mission is to simplify the process of finding quality
              educational opportunities for teachers and tutors, while also
              helping students and families find the right educational support
              tailored to their unique needs.
            </p>

            <p className="text-gray-600 leading-relaxed">
              By creating a seamless experience for both educators and students,
              we foster a community where learning thrives.
            </p>
          </div>

          {/* Vision Card */}
          <div className="bg-white rounded-2xl shadow-lg p-8 flex flex-col items-center text-center">
            {/* Graph Icon */}
            <div className="mb-6">
              <img src="/icons/vision.png" />
            </div>

            <h2 className="text-4xl font-bold mb-6">Our Vision</h2>

            <p className="text-gray-600 leading-relaxed">
              To revolutionize the education landscape by connecting talented
              teachers and tutors with students of all ages, enabling learning
              to happen everywhere—whether in classrooms, at home, or online.
            </p>
          </div>
        </div>
      </section>

      <section className="container py-16">
        {/* Main heading */}
        <h1 className="text-4xl font-bold text-center mb-16">
          What <span className="text-[#7BC144]">We Offer</span>
        </h1>

        <div className="flex flex-col md:flex-row gap-4">
          {/* Central image */}
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img
              src="/images/we-offer.png"
              alt="Student studying"
              className="w-full rounded-lg shadow-lg"
            />
          </div>

          {/* Cards Container */}
          <div className="md:w-1/2 space-y-6">
            {/* Sign Up Card */}
            <div className="p-6 rounded-lg shadow-lg border-2">
              <h3 className="text-blue-600 text-xl font-semibold mb-3">
                Sign Up
              </h3>
              <p className="text-gray-600 text-sm">
                Access A Wide Range Of Tutors, Teaching Opportunities, And
                Flexible Learning Options Tailored To Your Needs.
              </p>
            </div>

            {/* For Teachers Card */}
            <div className="p-6 rounded-lg shadow-lg border-2">
              <h3 className="text-blue-600 text-xl font-semibold mb-3">
                For Teachers
              </h3>
              <p className="text-gray-600 text-sm">
                Access Diverse Teaching Positions, Create Profiles, Upload
                Resumes, And Apply Directly Across Various Subjects And
                Settings.
              </p>
            </div>

            {/* For Home Tutors & Coaches Card */}
            <div className="p-6 rounded-lg shadow-lg border-2">
              <h3 className="text-blue-600 text-xl font-semibold mb-3">
                For Home Tutors & Coaches
              </h3>
              <p className="text-gray-600 text-sm">
                Find Tutoring And Coaching Opportunities, Offer Personalized
                Lessons In-Person Or Virtually, And Build A Profile To Showcase
                Your Skills And Connect With Students.
              </p>
            </div>

            {/* For Students & Parents Card */}
            <div className="p-6 rounded-lg shadow-lg border-2">
              <h3 className="text-blue-600 text-xl font-semibold mb-3">
                For Students & Parents
              </h3>
              <p className="text-gray-600 text-sm">
                Access A Database Of Tutors For Various Subjects, Search By
                Location And Tutoring Style, And Communicate Directly With
                Educators To Find The Perfect Fit.
              </p>
            </div>
          </div>
        </div>

        <div className="text-center mt-16">
          <h2 className="text-3xl font-bold mb-6">
            Ready to join our educational community?
          </h2>
          <Link to='/register1' className="w-fit bg-blue-600 text-white px-8 py-3 rounded-md hover:bg-blue-700 transition-colors">
            REGISTER NOW
          </Link>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AboutPage;
