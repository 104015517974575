import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Footer from "../../CommonWidget/Footer/Footer";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const scrollRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const bannerContent = [
    {
      title: "Thriving Together: Tutors, Students, and Institutions",
      description:
        "Connecting Teachers with Opportunities: Find part-time and full-time teaching jobs for schools, colleges, and home tuitions in India and abroad, including regions such as the Middle East, Africa, and Southeast Asia",
    },
    {
      title:
        "Provide resources or partner with legal experts to assist with work visas and immigration for teachers",
      description:
        "Connect with institutions worldwide and take the next step in your teaching career with our comprehensive platform",
    },
    {
      title:
        "Verify qualifications, certifications, and experience of registered teachers",
      description:
        "Implement a review and feedback mechanism for both teachers and employers",
    },
  ];

  const jobs = [
    {
      id: 1,
      company: "Linear Company",
      companyLogo:
        "https://logodownload.org/wp-content/uploads/2015/04/whatsapp-logo-7.png",
      title: "Drawing Teacher",
      location: "Mumbai",
      type: "Full time",
      salary: "$10-77k",
      posted: "29 min ago",
      description:
        "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },

    {
      id: 2,
      company: "Acnisdi Company",
      companyLogo:
        "https://www.freepnglogos.com/uploads/company-logo-png/company-logo-telestream-press-kit-12.png",
      title: "Software Trainer",
      location: "Chennai",
      type: "Full time",
      salary: "$30-55k",
      posted: "2 days ago",
      description:
        "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },

    {
      id: 3,
      company: "Acnisdi Company",
      companyLogo:
        "https://i.pinimg.com/originals/69/e0/64/69e0645c1a01e38f6092dac2703e760d.png",
      title: "Front-end Developer",
      location: "Kerala",
      type: "Full time",
      salary: "$50-55k",
      posted: "1 week ago",
      description:
        "lorecd lojndvd sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },

    {
      id: 4,
      company: "Techz Company",
      companyLogo:
        "https://www.freepnglogos.com/uploads/company-logo-png/bmw-car-company-logo-png-transparent-image-3.png",
      title: "Back-end Developer",
      location: "Bangalore",
      type: "Full time",
      salary: "$10-45k",
      posted: "3 week ago",
      description:
        "lorecd lojndvd sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === bannerContent.length - 1 ? 0 : prevIndex + 1
      );
    }, 12000);

    return () => clearInterval(interval);
  }, []);

  const slideStyles = {
    transform: `translateX(-${currentIndex * 100}%)`,
    transition: "transform 0.5s ease-in-out",
  };

  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? bannerContent.length - 1 : prevIndex - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === bannerContent.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="w-full min-h-screen bg-white">
      <Navbar />

      <div className="max-w-7xl mx-auto">
        {/* Hero Section */}
        <div className="relative w-screen left-[calc(-50vw+50%)] w-full bg-[url('/public/images/all_together.webp')] bg-cover bg-center bg-no-repeat w-full relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-black/50"></div>

          <div ref={scrollRef} className="relative">
            <div
              className="flex transition-transform duration-500"
              style={slideStyles}
            >
              {bannerContent.map((content, index) => (
                <div
                  key={index}
                  className="min-w-full flex flex-col lg:flex-row items-center justify-between py-12 lg:py-40 px-20 lg:px-8 gap-8"
                >
                  <div className="w-full lg:w-3/4 space-y-12">
                    <h1 className="text-3xl lg:text-5xl font-bold text-white">
                      {content.title}
                    </h1>
                    <p className="text-gray-300 text-lg leading-relaxed">
                      {content.description}
                    </p>
                    <button className="min-w-48 bg-blue-600 text-white font-bold px-12 py-4 rounded-lg hover:bg-blue-700">
                      <Link to="/register1">Signup Now</Link>
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Navigation Arrows */}
            <button
              onClick={handlePrevSlide}
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/20 hover:bg-white/30 p-2 rounded-full backdrop-blur-sm transition-all"
            >
              <ChevronLeft className="w-6 h-6 text-white" />
            </button>
            <button
              onClick={handleNextSlide}
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/20 hover:bg-white/30 p-2 rounded-full backdrop-blur-sm transition-all"
            >
              <ChevronRight className="w-6 h-6 text-white" />
            </button>

            {/* Navigation Dots */}
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
              {bannerContent.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full transition-colors duration-200 ${
                    currentIndex === index ? "bg-blue-600" : "bg-white/50"
                  }`}
                  onClick={() => setCurrentIndex(index)}
                />
              ))}
            </div>
          </div>
        </div>

        {/* AI Powered Job Portal Section */}
        <div className="py-16 px-4 bg-green-50">
          <h2 className="flex items-center justify-center mb-8">
            <img src="/images/electric.gif" className="h-16" />
            <span className="text-3xl lg:text-4xl font-bold">
              AI-Powered Job Matching
            </span>
            <img src="/images/electric.gif" className="h-16" />
          </h2>
          <p className="text-gray-600 text-center text-lg mb-12">
            Our advanced AI algorithms match tutors with the perfect teaching
            opportunities based on skills, experience, and preferences.
          </p>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {[
              {
                id: 1,
                icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2",
                title: "Smart Resume Analysis",
                description:
                  "AI-powered resume scanning identifies your key skills and qualifications to match you with relevant opportunities.",
              },
              {
                id: 2,
                icon: "M13 10V3L4 14h7v7l9-11h-7z",
                title: "Instant Matching",
                description:
                  "Get real-time job recommendations tailored to your profile, location preferences, and teaching style.",
              },
              {
                id: 3,
                icon: "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z",
                title: "Success Prediction",
                description:
                  "Our AI analyzes success patterns to suggest positions where you're most likely to excel and grow.",
              },
            ].map((feature) => (
              <div
                key={feature.id}
                className="bg-white rounded-xl p-6 shadow-md"
              >
                <div className="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-lg mb-4">
                  <svg
                    className="w-6 h-6 text-blue-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d={feature.icon}
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>

          <div className="text-center mt-12">
            <Link
              to="/register1"
              className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors inline-block font-semibold"
            >
              Try AI Matching
            </Link>
          </div>
        </div>

        {/* What is TutorPlatter Section */}
        <div className="py-16 px-4">
          <h2 className="text-3xl lg:text-4xl font-bold text-center mb-8">
            What is TutorPlatter?
          </h2>
          <p className="text-gray-600 text-center max-w-3xl mx-auto mb-12">
            TutorPlatter is dedicated to bridging the gap between students and
            top notch tutors worldwide.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* For Tutors */}
            <div className="border border-gray-200 rounded-lg p-6 hover:shadow-lg transition-shadow">
              <img
                src="https://i.ibb.co/fvJZ1Gg/Job.png"
                alt="tutor"
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold mb-4">For Tutors</h3>
              <p className="text-gray-600">
                Become part of TutorPlatter's vibrant and supportive network of
                educators.
              </p>
            </div>

            {/* For Educational Institutes */}
            <div className="border border-gray-200 rounded-lg p-6 hover:shadow-lg transition-shadow">
              <img
                src="https://i.ibb.co/WkvRW2W/Deal.png"
                alt="institute"
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold mb-4">
                For Educational Institutes
              </h3>
              <p className="text-gray-600">
                Partner with TutorPlatter to enhance your institute's
                educational offerings.
              </p>
            </div>

            {/* For Students */}
            <div className="border border-gray-200 rounded-lg p-6 hover:shadow-lg transition-shadow">
              <img
                src="https://i.ibb.co/60NF6KQ/Student.png"
                alt="student"
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold mb-4">For Students</h3>
              <p className="text-gray-600">
                Discover the ideal tutor who meets your specific academic needs
                with ease.
              </p>
            </div>
          </div>
        </div>

        {/* First Promotional Section */}
        <div className="bg-[url('https://i.ibb.co/z8bpznY/Rectangle-2742.png')] bg-cover bg-center w-full">
          <div className="flex flex-col lg:flex-row items-center justify-between py-12 lg:py-16 px-4 lg:px-16 gap-8">
            <img
              src="https://i.ibb.co/BwpK5GM/Desktop-1.png"
              alt="Find Your Tutor"
              className="w-full lg:w-1/2 object-contain order-2 lg:order-1"
            />
            <div className="w-full lg:w-1/2 text-white flex flex-col gap-6 order-1 lg:order-2">
              <h2 className="text-3xl lg:text-4xl text-white font-bold">
                Find Your Ideal Tutor
              </h2>
              <p className="text-xl text-white">Get Your Tutor Today!</p>
              <Link
                to="/register1"
                className="w-fit bg-white text-blue-600 px-8 py-3 text-lg font-semibold rounded hover:bg-gray-100 transition-colors"
              >
                Sign Up For Free
              </Link>
            </div>
          </div>
        </div>

        {/* Teaching Career Section */}
        <div className="py-16 px-4">
          <h2 className="text-3xl lg:text-4xl font-bold text-center mb-8">
            Do You Want a Teaching Career?
          </h2>
          <p className="text-gray-600 text-center max-w-3xl mx-auto mb-12">
            Take your teaching skills overseas. Improve your resume. Whatever
            your path, all of our programs are developed with one thing in mind:
            the power of a great teacher.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {jobs.map((job) => (
              <div
                key={job.id}
                className="border border-gray-200 rounded-lg p-6 hover:shadow-lg transition-shadow"
              >
                <div className="flex flex-col sm:flex-row gap-4">
                  <img
                    src={job.companyLogo}
                    alt={job.company}
                    className="w-12 h-12 rounded-full"
                  />
                  <div className="flex-1">
                    <p className="text-gray-600">{job.company}</p>
                    <h3 className="text-xl font-semibold">{job.title}</h3>
                    <div className="flex flex-wrap gap-4 mt-2 text-sm text-gray-500">
                      <p className="flex items-center gap-1">
                        <i className="bi bi-briefcase"></i> {job.type}
                      </p>
                      <p className="flex items-center gap-1">
                        <i className="bi bi-currency-dollar"></i> {job.salary}
                      </p>
                      <p className="flex items-center gap-1">
                        <i className="bi bi-geo-alt"></i> {job.location}
                      </p>
                      <p className="flex items-center gap-1">
                        <i className="bi bi-clock"></i> {job.posted}
                      </p>
                    </div>
                    <p className="mt-4 text-gray-600">{job.description}</p>
                  </div>
                  <button className="text-gray-400 hover:text-blue-500 transition-colors">
                    <i className="bi bi-bookmark"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Second Promotional Section */}
        <div className="bg-[url('https://i.ibb.co/z8bpznY/Rectangle-2742.png')] bg-cover bg-center w-full">
          <div className="flex flex-col lg:flex-row items-center justify-between py-12 lg:py-16 px-4 lg:px-16 gap-8">
            <div className="w-full lg:w-1/2 text-white flex flex-col gap-6">
              <h2 className="text-3xl lg:text-4xl text-white font-bold">
                Join Our Network of Expert Tutors
              </h2>
              <p className="text-xl text-white">
                Create Your Tutor Profile Today!
              </p>
              <Link
                to="/register1"
                className="w-fit bg-white text-blue-600 px-8 py-3 text-lg font-semibold rounded hover:bg-gray-100 transition-colors"
              >
                Sign Up For Free
              </Link>
            </div>
            <img
              src="https://i.ibb.co/Yd3KPhP/Desktop.png"
              alt="Join Network"
              className="w-full lg:w-1/2 object-contain"
            />
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
